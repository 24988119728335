import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { cx, css } from 'linaria';
import { styled } from 'linaria/react';
import React, { useContext, useEffect, useState } from 'react';
import { Price } from '../Price';
import { AddFavourite } from '../ProductList/AddFavourite';
import { usePriceDiffer } from '../ProductPage/usePriceDiffer';
import { theme } from '../Theme';
import t from '@jetshop/intl';
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';
import { badgePriority } from '../../utils/badgePriority';
import { useGlobalSettings } from '../Settings/GlobalSettings';
import {
  getArtNoPartial,
  getBrand,
  isSaleProduct
} from '../ProductPage/ProductPage';
import { StockStatusQuery } from './StockStatusQuery.gql';
import { useQuery } from 'react-apollo';
import useVisibilitySettings from '../../utils/useVisibilitySettings';
import { useProductPricing } from '../../utils/useProductPricing';
import { ReactComponent as Check } from '../../svg/Check.svg';
import { ReactComponent as RedCross } from '../../svg/Cross.svg';
import { GmfProductRating } from './GmfProductRating';

export const productTitleStyle = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  min-height: calc(14px * 1.2 * 2);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-transform: uppercase;
  color: ${theme.colors.primary};
  text-align: left;
  margin: 0;
  letter-spacing: 0.02em;
`;

export const productBrandStyle = css`
  margin-top: 2px;
  font-weight: 500;
  font-size: 11px;
  color: ${theme.colors.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: left;
  letter-spacing: 0.06em;
`;

const Wrapper = styled('li')`
  font-size: 1rem;
  position: relative;

  a {
    width: 100%;
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  .product-card-detail {
    background: white;
    padding: 1rem 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .product-card-rating-and-availability {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      ${theme.below.md} {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 6px;
        .gmf-product-rating {
          min-height: 20px;
        }
      }
    }

    header {
      width: 100%;
    }
  }

  &.big-card {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      background: #f7f7f7;
    }

    .product-card-detail {
      background: transparent;
      padding: 1rem;

      .normal-price,
      .member-price {
        font-size: 3.5rem;
      }
    }
  }

  .badges-wrapper {
    & > div > div {
      top: 10px;
      div {
        margin-bottom: 5px;
      }
    }
    > div > div > div {
      font-weight: bold;
      font-size: 0.55rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1.1;
      text-align: center;
      width: 65px;
      height: 25px;
      color: white;
      background: #235837;
      ${theme.below.md} {
        height: 20px;
        font-size: 0.5rem;
        padding-top: 1px;
      }

      &.sale-tagg {
        background: #872c2b;
      }

      &.member-tagg {
        background: #b08832;
      }

      &.best-price-tagg {
        background: #ef7622;
      }

      &.new-tagg {
        background: #000;
      }
      &.member-deal-tagg {
        background: ${theme.colors.pink};
      }
    }
  }
`;

export const ProductSymbolWrapper = styled('div')`
  position: absolute;
  width: calc(${props => props.symbolSize} + 14px);
  height: calc(${props => props.symbolHeight} + 14px);
  z-index: 2;
  .product-symbol {
    padding-bottom: 0 !important;
    height: calc(${props => props.symbolHeight} + 14px) !important;
    display: content;
    picture {
      display: content;
    }
    img {
      padding: 0px !important;
      max-width: 100% !important;
      max-height: 100% !important;
      width: auto !important;
      height: auto !important;
      position: absolute !important;
    }
  }
  ${theme.below.md} {
    width: calc(${props => props.symbolSizeMobile} + 14px);
    height: calc(${props => props.symbolHeightMobile} + 14px);
    .product-symbol {
      height: calc(${props => props.symbolHeightMobile} + 14px) !important;
      width: calc(${props => props.symbolSizeMobile} + 14px) !important;
    }
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.03);
    pointer-events: none;
    z-index: 1;
  }
  .show-on-hover {
    opacity: 0;
    position: absolute !important;
    top: 0;
  }
  .percentage {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background: white;
    color: #878787;
    border-radius: 1rem;
    padding: 2px 8px;
    font-size: 0.8rem;
    z-index: 3;
    font-weight: bold;
  }

  ${theme.above.md} {
    &:hover {
      .show-on-hover {
        opacity: 1;
      }
      .main-image {
        opacity: 0;
      }
    }
  }

  img {
    padding: 3px !important;
  }
`;

export const PriceWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 700;
  font-size: 3rem;
  text-align: center;

  ${theme.below.sm} {
    font-size: 14px;
    margin-top: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    div {
      margin-right: 10px;
      color: ${theme.colors.primary};
    }

    --price-font-size: 2.5rem;
    --currency-font-size: 0.8rem;
    --price-font-weight: 600;
    ${theme.below.md} {
      --price-font-size: 2rem;
      --currency-font-size: 0.7rem;
      --price-font-weight: 600;
    }

    .fr {
      font-size: var(--currency-font-size);
      margin-bottom: 4px;
      margin-right: 4px;
    }

    .new-price,
    .price,
    .member-price {
      display: flex;
      align-items: baseline;

      .currency {
        font-size: var(--currency-font-size);
        text-transform: uppercase;
        margin-left: 4px;
      }
    }

    .new-price {
      color: #b13827;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'Figtree', sans-serif;
      font-size: var(--price-font-size);
      font-weight: var(--price-font-weight);
      line-height: 1;
      margin-right: 0;
    }
    .price {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'Figtree', sans-serif;
      font-size: var(--price-font-size);
      font-weight: var(--price-font-weight);
      line-height: 1;
      margin-right: 0;
    }
    .old-price {
      color: ${theme.colors.grey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1;
      font-weight: normal;
      display: flex;
      gap: 0.3rem;
      margin-top: 0.2rem;
      display: flex;
      align-items: flex-end;
      padding: 1px 0px;

      font-size: var(--currency-font-size);
      text-decoration: none;
      color: black !important;
      font-weight: bold !important;
      text-transform: uppercase;
      text-decoration: line-through;

      .price-value-and-currency {
        display: flex;
        align-items: flex-end ;
        span {
          margin-bottom: 0px !important;
        }
      }
    }
    .member-price {
      color: ${theme.colors.pink};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'Figtree', sans-serif;
      font-size: var(--price-font-size);
      font-weight: var(--price-font-weight);
      line-height: 1;
      margin-right: 0;
    }
  }
  &.price-range {
    .price,
    .old-price,
    .new-price {
      display: flex;
      align-items: flex-end;
      &::before {
        display: inline-block;
        content: ${props => props.fromText};
        margin-right: 3px;
        padding-bottom: 5px;
        font-size: 1rem;
        font-family: ${theme.fonts.primary};
      }
    }

    .old-price {
      padding-bottom: 4px;
      &::before {
        padding-bottom: 0px;
      }
    }
  }
`;

const AvailabilityWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;

  ${theme.below.md} {
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 8px;
  }

  .location {
    position: relative;
    display: flex;
    align-items: center;
    p {
      text-transform: uppercase;
      margin-right: 8px;
      font-size: 0.625rem;
      color: #878787;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 0.08em;
    }
  }
  @media screen and (max-width: 430px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const parsePosition = position => {
  switch (position) {
    case 'Överkant - Höger':
      return {
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      };
    case 'Överkant - Vänster':
      return {
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      };
    case 'Nederkant - Höger':
      return {
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
      };
    case 'Nederkant - Vänster':
      return {
        bottom: 3,
        left: 3,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
      };
    default:
      return {
        top: 0,
        right: 0
      };
  }
};

export const getPercentage = product => {
  const divided = product?.price?.incVat / product?.previousPrice?.incVat;
  const fixed = divided.toFixed(2);
  const fixedPercentage = Math.floor((1 - fixed) * 100);
  return fixedPercentage;
};

export const getTemplateName = product => {
  const marketingCategoryObject = product?.customFields?.find(
    item => item.key === 'MarketingCategory'
  );

  // Check if the object exists and the "listValues" array is not empty
  if (
    marketingCategoryObject &&
    marketingCategoryObject?.listValues?.length > 0
  ) {
    // Get the first value from the "listValues" array
    const firstMarketingCategoryValue = marketingCategoryObject?.listValues[0];
    return firstMarketingCategoryValue;
  } else {
    return null;
  }
};

export const getMatchingTemplates = (product, templates) => {
  const marketingCategoryObject = product?.customFields?.find(
    item => item.key === 'MarketingCategory'
  );
  // Check if the object exists and the "listValues" array is not empty
  if (
    marketingCategoryObject &&
    marketingCategoryObject?.listValues?.length > 0
  ) {
    // Get the first value from the "listValues" array
    const marketingCategories = marketingCategoryObject?.listValues;
    const matchingTemplates = Object.keys(templates)?.filter(key => {
      return marketingCategories.includes(key);
    });
    return matchingTemplates;
  } else {
    return null;
  }
};

const checkStyle = css`
  width: 12px;
  height: 12px;
  path {
    fill: #4cd038;
  }
`;

const redCrossStyle = css`
  width: 10px;
  height: 10px;
  use {
    fill: red;
  }
`;

const Availability = ({ isBuyable, hasStockInStore }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const hasNoStores = selectedChannel?.id === 2 || selectedChannel?.id === 6;
  return (
    <AvailabilityWrapper>
      <div className="location">
        <p>{t('Webshop')}</p>
        {isBuyable ? (
          <Check className={checkStyle} />
        ) : (
          <RedCross className={redCrossStyle} />
        )}
      </div>
      {!hasNoStores && (
        <div className="location">
          <p>{t('Store')}</p>
          {hasStockInStore ? (
            <Check className={checkStyle} />
          ) : (
            <RedCross className={redCrossStyle} />
          )}
        </div>
      )}
    </AvailabilityWrapper>
  );
};

const checkStockLevels = product => {
  const checkVariants = () => {
    const variants = product?.variants?.values;
    if (variants?.length > 0) {
      const warehouseStock = variants?.map(variant => variant.warehouseStock);
      const hasStock = warehouseStock
        ?.map(location => location?.some(location => location.stockLevel > 0))
        .some(hasStock => hasStock === true);
      return hasStock;
    } else {
      return false;
    }
  };
  const singleHasStock = product?.warehouseStock?.some(
    location => location.stockLevel > 0
  );
  const variantsHasStock = checkVariants();
  if (singleHasStock || variantsHasStock) {
    return true;
  } else {
    return false;
  }
};

export const ProductCardWithStockStatus = ({ product }) => {
  const { data, loading, error } = useQuery(StockStatusQuery, {
    variables: {
      articleNumber: product?.articleNumber
    }
  });

  const newProduct = {
    ...product,
    stockStatus: data?.product?.stockStatus
  };

  return <RefinedProductCard product={newProduct} />;
};

export const removeBrandFromProductName = product => {
  const brand = getBrand(product);
  const productName = product?.name;
  const productNameWithoutBrand = productName?.replace(brand, '');
  return productNameWithoutBrand;
};

export function RefinedProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  loading,
  ...linkProps
}) {
  const hasNoPrice = !product?.price?.incVat === 0;
  const isBuyable = product?.stockStatus?.buyable;
  const hasStockInStore = checkStockLevels(product);
  const { loggedIn } = useAuth();
  const hasImages = product.images && product.images.length > 0;
  const { productTemplates, saleTemplate } = useGlobalSettings();
  const isSale = isSaleProduct(product);
  const templates = getMatchingTemplates(product, productTemplates);
  const fixedPercentage = getPercentage(product);
  const productTemplate = productTemplates[templates?.[0]] ?? null;
  const hasHeight = productTemplate?.symbolHeight !== null;
  const hasPriceDiffer = usePriceDiffer({
    variants: product?.variants?.values
  });
  let uniqueBadges = badgePriority(product.badges, 'name');
  const altHeart =
    productTemplate?.symbol &&
    productTemplate?.symbolPosition === 'Överkant - Höger'
      ? true
      : false;

  if (!loggedIn) {
    uniqueBadges = uniqueBadges?.filter(badge => badge?.name !== 'Medlem');
  }
  let fromText = 'Fr.';
  const hasMemberPrice = product?.hasMemberPrice;
  const productToUse = useProductPricing(product, hasMemberPrice, loggedIn);
  const saleBadges = [
    ...product?.badges,
    {
      name: 'SaleSpecial',
      url: null,
      location: 'TOP_LEFT',
      style: saleTemplate.tagClass,
      text: saleTemplate.tagText
    }
  ];
  const hasSaleTemplate = Object.keys(saleTemplate).length > 0;

  // Visibility settings for productTemplate
  const countryVisibilitySettings = {
    sweden: productTemplate?.sweden,
    denmark: productTemplate?.denmark,
    norway: productTemplate?.norway,
    finland: productTemplate?.finland,
    netherlands: productTemplate?.netherlands,
    eu: productTemplate?.eu,
    loggedIn: productTemplate?.loggedIn,
    loggedOut: productTemplate?.loggedOut
  };
  const doNotRender = useVisibilitySettings(countryVisibilitySettings);

  if (hasNoPrice) {
    return null;
  }

  return (
    <Wrapper
      data-testid="product"
      tmplt={productTemplate}
      className={cx(
        'product-card',
        className,
        productTemplate && 'templatedProduct'
      )}
    >
      <ProductLink
        product={product}
        loading={loading ? loading : undefined}
        {...linkProps}
      >
        <ImageWrapper>
          {productTemplate && productTemplate?.symbol && !doNotRender && (
            <ProductSymbolWrapper
              symbolSize={productTemplate?.symbolSize + 'px'}
              symbolHeight={
                hasHeight
                  ? productTemplate?.symbolHeight + 'px'
                  : productTemplate?.symbolSize + 'px'
              }
              symbolSizeMobile={productTemplate?.symbolSizeMobile + 'px'}
              symbolHeightMobile={productTemplate?.symbolHeightMobile + 'px'}
              style={parsePosition(productTemplate?.symbolPosition)}
            >
              <Image
                className="product-symbol"
                sizes={'100'}
                aspect={'1:1'}
                alt={productTemplate?.symbolText ?? ''}
                title={productTemplate?.symbolText ?? ''}
                src={productTemplate?.symbol}
              />
            </ProductSymbolWrapper>
          )}
          <AddFavourite
            className="add-fav"
            product={product}
            style={{
              float: 'right',
              marginLeft: 'auto',
              fontSize: '1.5em',
              marginTop: altHeart
                ? `calc(1.5rem + ${productTemplate?.symbolSize}px)`
                : '0.5rem'
            }}
          />

          {hasImages ? (
            <>
              <div className="badges-wrapper">
                <Badges
                  badges={isSale && hasSaleTemplate ? saleBadges : uniqueBadges}
                />
              </div>
              {fixedPercentage > 0 && (
                <div className="percentage test">-{fixedPercentage}%</div>
              )}
              <Image
                className={cx(
                  product.images.length > 1 ? 'main-image' : 'only-image'
                )}
                sizes={imageSizes}
                aspect={imageAspect}
                alt={product.images[0].alt}
                src={product.images[0].url}
                modifiedDate={product.images[0].modifiedDate}
                critical={true}
              />
              {product?.images?.length > 1 && (
                <Above breakpoint="md">
                  {matches => (
                    <Image
                      className="show-on-hover"
                      sizes={imageSizes}
                      aspect={imageAspect}
                      alt={product?.images[1]?.alt}
                      src={product?.images[1]?.url}
                      modifiedDate={product?.images[1]?.modifiedDate}
                    />
                  )}
                </Above>
              )}
            </>
          ) : (
            <Image aspect={imageAspect} />
          )}
        </ImageWrapper>

        <section className="product-card-detail">
          <div className="product-card-rating-and-availability">
            <GmfProductRating
              productId={getArtNoPartial(product?.articleNumber)}
            />
            <Availability
              isBuyable={isBuyable}
              hasStockInStore={hasStockInStore}
            />
          </div>

          <header style={{ flexDirection: 'column' }}>
            <h4 className={cx('sub-name', productBrandStyle)}>
              {getBrand(product)}&nbsp;
            </h4>
            <h3 className={cx(productTitleStyle)}>
              {removeBrandFromProductName(product)}
            </h3>
          </header>

          <PriceWrapper
            fromText={fromText}
            className={hasPriceDiffer ? 'price-range' : 'single-price'}
          >
            <Price
              hasMemberPrice={hasMemberPrice}
              price={productToUse?.price}
              previousPrice={productToUse?.previousPrice}
              fromPrice={hasPriceDiffer}
            />
          </PriceWrapper>
        </section>
      </ProductLink>
    </Wrapper>
  );
}
