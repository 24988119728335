import React from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';

import { theme } from '../../Theme';

import ProductCarousel from '../../ui/ProductCarousel';
import getFavoritesQuery from './getFavoritesQuery.gql';
import { RefinedProductCard } from '../../CategoryPage/RefinedProductCard';

const ProductGridWrapper = styled('div')`
  max-width: 90rem;
  margin: auto;
  padding: 3rem 2rem;
  ${theme.below.lg} {
    padding: 2rem 0;
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 0.35rem;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
  }
`;

const Favorites = ({ id, title }) => {
  if (!id) {
    return null;
  }
  id = parseFloat(id);
  return (
    <React.Fragment>
      <Query query={getFavoritesQuery} variables={{ id: id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return '';
          }
          if (error) return null;
          if (!data.category) {
            return null;
          }
          return (
            <ProductGridWrapper>
              {title ? <h2>{title}</h2> : null}
              <ProductCarousel
                products={data.category.products.result}
                listName={data.category.name}
                categoryPath={data.category.primaryRoute}
                loading={loading}
                ProductComponent={RefinedProductCard}
              />
            </ProductGridWrapper>
          );
        }}
      </Query>
    </React.Fragment>
  );
};

export default Favorites;
