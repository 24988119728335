import t from '@jetshop/intl';
import { ErrorMessage, Field } from 'formik';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import {
  InputError,
  InputPositive,
  ToggleViewPasswordButton
} from './InputStatus';

const Input = styled('input')`
  height: 40px;
  background: #fcfcfc;
  border: 1px solid #000;
  border-left: 0;
  border-right: 0;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-bottom: 0.25rem;
  padding-right: 40px;
  border-radius: 0;
  &:disabled {
    background: #ffffff;
    color: #808080;
  }
  &.quantity-input:disabled {
    background: #fff;
    color: #000;
  }
`;

export const Wrapper = styled('div')`
  margin-bottom: 1.5rem;
  &.quantity-input-wrapper {
    display: flex;
  }
`;

export const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
`;

export const Label = styled('label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.25rem;
  display: block;
  .req {
    color: hsl(0, 0%, 60%);
    margin-left: 0.5em;
  }
`;

const QuantityButton = styled('button')`
  background: #f6f6f6;
  border: 1px solid #000;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  &:focus {
    outline: none;
  }
`;

const QuantityInput = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  disableValidation,
  setValues,
  values,
  maximum,
  value,
  ...props
}) => {
  const [currentType, setType] = useState(type);
  useEffect(() => {
    value && setValues({ ...values, quantity: value });
  }, [value]);

  return (
    <Wrapper className={props.wrapperClassName}>
      <Field
        disabled={disabled}
        id={name}
        name={name}
        render={({ field, form: { touched, errors } }) => (
          <>
            <QuantityButton
              type="button"
              onClick={() => {
                setValues({
                  ...values,
                  quantity: values.quantity === 1 ? 1 : values.quantity - 1
                });
              }}
            >
              -
            </QuantityButton>
            <Input
              {...props}
              {...field}
              type={currentType}
              disabled={disabled}
              className="quantity-input"
              value={values.quantity}
            />
            <QuantityButton
              type="button"
              onClick={() => {
                setValues({
                  ...values,
                  quantity:
                    values.quantity >= maximum ? maximum : values.quantity + 1
                });
              }}
            >
              +
            </QuantityButton>
          </>
        )}
      />
      {!disableValidation && <ErrorMessage name={name} component={ErrorSpan} />}
    </Wrapper>
  );
};

export default QuantityInput;
