import React from 'react';
import { styled } from "linaria/react";
import { theme } from "../../Theme";
import { TargetedLink } from '../../StartPage/ContentRendererComponents/ColorBox';
import { BoxButton } from '../../StartPage/ContentRendererComponents/ColorBox';

const TextWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: ${props => props.textPosition};
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: ${props => props.textPosition};
    justify-content: ${props => props.textPosition};
    background: ${props => props.background};
    width: ${props => props.width ? '100%' : 'fit-content'};
  }
  .text-content {
    color: ${props => props.color};
    max-width: 750px;
    padding: 1.5rem;
    p {
      margin: 0;
      line-height: 1.2;
    }
    a {
      color: ${props => props.color} !important;
    }
  }
  .wideLink {
    padding-bottom: 1.5rem;
  }
`;

const positionClasses = {
  'mitten': 'center',
  'höger': 'flex-end',
  'vänster': 'flex-start',
}

const Text = ({ 
    text, 
  textColor, 
  backgroundColor, 
  position, 
  fullWidth,
  buttonText,
  buttonTheme,
  link,
}) => {
  const color = textColor?.value ?? 'svart';
  const background = backgroundColor?.value ?? 'vit';
  const width = fullWidth?.value ?? false;
  const textContent = text?.value;
  const textPosition = positionClasses[position?.value] ?? 'center';
  const btnTheme = buttonTheme?.value;
  const btnText = buttonText?.value;
  const linkUrl = link?.value;

  const colorClasses = {
    'svart': theme.colors.black,
    'vit': theme.colors.white,
    'grön': theme.colors.accent,
    'röd': theme.colors.christmasred,
  }

  return (
    <TextWrapper
        textPosition={textPosition}
        color={colorClasses[color]}
        background={colorClasses[background]}
        width={width}
    >
      <div className="inner-wrapper">
        <div 
          className="text-content" 
          dangerouslySetInnerHTML={{ __html: textContent }} />
        {btnTheme && btnText && linkUrl && (
          <a href={linkUrl} className="wideLink">
            <BoxButton className={btnTheme}>{btnText}</BoxButton>
          </a>
        )}
      </div>
    </TextWrapper>
  );
};

export default Text;
