import React from 'react'
import { DynamicCategoryRenderer } from '../StartPage/ContentRendererComponents/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { allComponents } from '../StartPage/ContentRendererComponents/allcomponents';
import MaxWidth from '../Layout/MaxWidth';
import { css } from "linaria";
import { theme } from "../Theme";
import CategoryContentQuery from '../StartPage/ContentRendererComponents/DynamicCategoryRenderer/CategoryContentQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from 'react-apollo';
import { BonusLevels } from './ClubHooksBonusLevels';
import { HeaderWrapper, LogoWrapper } from './SharedComponents';
import { ReactComponent as ClubHooksLogo } from './imgs/clubhooks_shield.svg';
import { useLocation } from 'react-router-dom';
const ClubHooksPageStyles = css`
  background-color: ${theme.colors.white};
  max-width: 45rem;
  padding:1.5rem 1.5rem 0 1.5rem;
  margin-bottom: 2rem;
  > * {
    padding: 0;
  }
  h1 {
    text-align: left;
    margin: 0;
    padding-bottom: .7rem;
    font-size: 24px;
  }
  h2 {
    margin: 0 !important;
    padding: .7rem 0;
  }
  .text-content:first-of-type {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  .text-content {
    padding: 0 !important;
    margin-bottom: 1.5rem;
  }
  .box-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  .box-row > * {
    width: calc(50% - 20px) !important;
    margin: 0 !important;
    a {
        height: auto;
        text-align: left;
    }
    p {
        margin: 0;
        line-height: 1.2;
        text-align: left;
        padding: 0;
    }
    .under {
        padding: 0;
        margin: 0 !important;
    }
  }
  ${theme.below.md} {
        margin-bottom: 1rem;
        max-width: 100%;
        .box-row > * {
            width: 100% !important;
            h2 {
                font-size: 24px;
            }
            p {
                font-size: 16px;
            }
        }
    }
`;

// This function splits the items into two arrays, one for the items above the divider and one for the items below the divider
const splitItems = (items) => {
    // Guard against undefined/null
    if (!items || !Array.isArray(items)) {
      return { itemsAbove: [], itemsBelow: [] };
    }

    const dividerIndex = items.findIndex(item => 
      item?.properties?.some(prop => 
        prop.name === 'text' && prop.value?.value?.includes('[BonusLevels]')
      )
    );

    // If divider not found, return all items in itemsAbove
    if (dividerIndex === -1) {
      return {
        itemsAbove: [...items],
        itemsBelow: []
      };
    }

    //get the bonus descriptions from dividerIndex
    const bonusText = items[dividerIndex]?.properties?.find(prop => prop.name === 'text')?.value?.value;

    return {
      itemsAbove: items.slice(0, dividerIndex),
      itemsBelow: items.slice(dividerIndex + 1),
      bonusText
    };
  };

const ClubHooksPage = () => {
    const location = useLocation();
    const isMyPages = location.pathname.includes('/my-pages');

    const { data, error } = useQuery(CategoryContentQuery, {
        variables: {
            id: 3743
        }
    });
    if (!data || error) {
        return null;
    }

    const items = data?.category?.data?.items;
    const components = allComponents;
    const { itemsAbove, itemsBelow, bonusText } = splitItems(items);

    
  return (
    <>
    {!isMyPages && (
    <HeaderWrapper>
        <LogoWrapper>
            <ClubHooksLogo />
        </LogoWrapper>
    </HeaderWrapper>
    )}
    <MaxWidth className={ClubHooksPageStyles}>
        <h1>Club Hööks</h1>
        {items && <ContentRenderer items={itemsAbove} components={components} />}
        <BonusLevels bonusText={bonusText} />
        {items && <ContentRenderer items={itemsBelow} components={components} />}
    </MaxWidth>
    </>
  )
}

export default ClubHooksPage;
