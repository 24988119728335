import { styled } from "linaria/react";
import { theme } from "../Theme";
import myPagesHeaderBg from '../../img/color_bg.jpg';

export const HeaderWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 240px;
  background: url(${myPagesHeaderBg}) no-repeat center center;
  background-size: 100% auto;
  background-position: bottom -100px center;

  ${theme.below.md} {
    background-position: bottom 0px center;
    justify-content: center;
  }
  ${theme.below.sm} {
    height: 200px;
    background-position: bottom 0px center;
  }
`;

export const LogoWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 220px;
  }
  ${theme.below.md} {
    height: 200px;
    svg {
      height: 180px;
    }
  }
`; 