import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Intl } from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { Price } from '../Price';
import { AddFavourite } from '../ProductList/AddFavourite';
import { usePriceDiffer } from '../ProductPage/usePriceDiffer';
import { theme } from '../Theme';
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';
import { badgePriority } from '../../utils/badgePriority';
import { useGlobalSettings } from '../Settings/GlobalSettings';
import { isSaleProduct } from '../ProductPage/ProductPage';
import useVisibilitySettings from '../../utils/useVisibilitySettings';
import wow from '../../img/WOW_highRes.png';
import clubHooks from '../../img/ClubHooks_Shield.png';
import member from '../../img/MemberDealLarge.png';
import newStandard from '../../img/NewStandard.png';
import outlet from '../../img/OutletLarge.png';
import { useProductPricing } from '../../utils/useProductPricing';
import { PriceWrapper } from './RefinedProductCard';

const Wrapper = styled('li')`
  font-size: 1rem;
  position: relative;
  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
    background: white;
    > * {
      flex: 1;
    }
  }

  .product-card-detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: white;
    padding: 0.7rem 1rem;
    line-height: 1.35;
    width: 100%;
    text-align: left;
    overflow: hidden;
    height: 280px;
    ${theme.below.lg} {
      height: 180px;
    }
    ${theme.below.sm} {
      height: 160px;
    }
    /* > * {
      flex: 1;
    } */

    header {
      width: 100%;
      margin-bottom: 5px;
    }

    .product-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal; /* Change from wrap to normal */
      font-weight: bold;
      font-size: 17px;
      text-transform: uppercase;
      color: ${theme.colors.primary};
      min-height: 45px;
      max-width: 75%;
      ${theme.below.sm} {
        font-size: 14px;
        min-height: 30px;
      }
    }

    .brand-name {
      font-weight: bold;
      font-size: 12px;
      color: ${theme.colors.primary};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      ${theme.below.sm} {
        font-size: 10px;
      }
    }
    .gmf-product-rating:has(.gmf-rating-stars) {
      align-items: flex-end !important;
    }
    .gmf-product-rating.gmf-context {
      padding-bottom: 0px;
    }
    .gmf-context .gmf-text-small,
    .gmf-text-small {
      padding-bottom: 4px;
    }
    .price-value-and-currency {
      margin-bottom: 5px;
    }

    .old-price {
      padding-left: 7px;
    }
    .price {
      .price-value {
        font-weight: bold;
        font-size: clamp(3rem, 4vw, 4rem) !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 0.8;
        margin-right: 0;
        display: flex;
        align-items: flex-end;
      }
      .currency {
        font-size: clamp(1.2rem, 1.5vw, 2rem);
        text-transform: uppercase;
        margin-bottom: 0;
        margin-left: ${props => (props.isNorway ? '0' : '-5%')};
        margin-right: ${props => (props.isNorway ? '-5%' : '0')};
        line-height: 1;
        padding-bottom: 0.3em;
      }
    }

    .member-price {
      margin-top: -0.5rem;
      .price-value {
        color: ${theme.colors.pink};
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: 'Figtree', sans-serif;
        font-size: clamp(3rem, 4vw, 4rem) !important;
        margin-right: 0;
        margin-top: 0;
        line-height: 0.8;
        display: flex;
        align-items: flex-end;
      }
      .currency {
        font-size: clamp(0.7rem, 1.5vw, 1.5rem);
        text-transform: uppercase;
        margin-bottom: 0;
        margin-left: ${props => (props.isNorway ? '0' : '-5%')};
        margin-right: ${props => (props.isNorway ? '-5%' : '0')};
        line-height: 1;
        padding-bottom: 0.3em;
      }
    }
  }

  .badges-wrapper {
    & > div > div {
      top: 15px;
      div {
        margin-bottom: 5px;
      }
    }
    > div > div > div {
      font-weight: bold;
      font-size: clamp(0.6rem, 1.5vw, 0.8rem);
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1.1;
      text-align: center;
      width: clamp(80px, 35%, 110px);
      height: clamp(25px, 5vw, 35px);
      color: white;
      background: #235837;
      padding-top: 2px;

      &.sale-tagg {
        background: #872c2b;
      }

      &.member-tagg {
        background: #b08832;
      }

      &.best-price-tagg {
        background: #ef7622;
      }

      &.new-tagg {
        background: #000;
      }

      &.member-deal-tagg {
        background: ${theme.colors.pink};
      }
    }
  }
`;

// const SPECIAL = `
//   &.templatedProduct {
//     border: ${props => (props?.tmplt?.showBorder ? '1' : '0')}px solid
//       ${props => props?.tmplt?.borderColor};
//     &,
//     .product-card-detail {
//       background: ${props => props?.tmplt?.backgroundColor ?? 'transparent'};
//     }
//     ${Price.Old},${Price.Normal} {
//       font-size: ${props => props?.tmplt?.defaultPrice_size}px;
//       color: ${props => props?.tmplt?.defaultPrice_color};
//     }
//   }
// `;

export const ProductSymbolWrapper = styled('div')`
  position: absolute;
  right: 4%;
  bottom: -20%;
  width: clamp(50px, 33%, 180px);
  height: 35%;
  z-index: 2;
  .product-symbol {
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.03);
    pointer-events: none;
    z-index: 1;
  }
  .show-on-hover {
    opacity: 0;
    position: absolute !important;
    top: 0;
  }
  .percentage {
    position: absolute;
    bottom: 0.5rem;
    left: 1.3rem;
    background: white;
    color: #878787;
    border-radius: 1rem;
    padding: 2px 8px;
    font-size: 0.8rem;
    z-index: 3;
    font-weight: bold;
  }

  ${theme.above.md} {
    &:hover {
      .show-on-hover {
        opacity: 1;
      }
      .main-image {
        opacity: 0;
      }
    }
  }

  img {
    padding: 3px !important;
  }
`;

// const AddFavoriteButtonWrapper = styled('div')`
//   position: absolute;
//   top: 15px;
//   right: 15px;
//   z-index: 1;

//   ${theme.below.sm} {
//     top: 10px;
//     right: 10px;
//     .favorite-icon svg {
//       width: 20px;
//     }
//   }
// `;

// const PriceRange = styled('span')`
//   font-size: 0.85rem;
//   margin-right: 5px;
//   line-height: 1;
// `;

// const PriceWrapper = styled('div')`
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   font-weight: 700;

//   > div {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;

//     .new-price {
//       color: #b13827;
//       margin-right: 8px;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       overflow: hidden;
//       font-family: 'Figtree', sans-serif;
//       font-size: clamp(2rem, 4vw, 4rem);
//       line-height: 0.8;
//       div {
//         color: #b13827;
//       }
//       span {
//         font-size: 0.4em;
//         text-transform: uppercase;
//         margin-left: ${props => (props.isNorway ? '0' : '-7%')};
//         margin-right: ${props => (props.isNorway ? '-7%' : '0')};
//       }
//     }

//     .price {
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       overflow: hidden;
//       font-family: 'Figtree', sans-serif;
//       font-size: clamp(2rem, 4vw, 4rem);
//       line-height: 0.8;
//       margin-right: 0;
//       span {
//         font-size: 0.4em;
//         text-transform: uppercase;
//         margin-left: ${props => (props.isNorway ? '0' : '-5%')};
//         margin-right: ${props => (props.isNorway ? '-5%' : '0')};
//       }
//     }
//     .old-price {
//       color: ${theme.colors.black};
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       overflow: hidden;
//       margin-right: 5px;
//       padding: 0;
//       margin-top: 10px;
//       line-height: 1;
//       font-weight: normal;
//       padding-left: 5px;
//       div {
//         color: ${theme.colors.black};
//         font-size: 0.9rem;
//         text-align: left;
//       }
//       span {
//         text-transform: uppercase;
//       }
//       &.member {
//         display: flex;
//         align-items: center;
//         gap: 5px;
//         justify-content: flex-start;
//         font-size: 0.9rem;
//       }
//     }
//     .member-price {
//       color: ${theme.colors.pink};
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       overflow: hidden;
//       font-family: 'Figtree', sans-serif;
//       font-size: clamp(2rem, 4vw, 4rem);
//       line-height: 0.8;
//       margin-right: 0;
//       span {
//         font-size: 0.4em;
//         text-transform: uppercase;
//         margin-left: ${props => (props.isNorway ? '0' : '-5%')};
//         margin-right: ${props => (props.isNorway ? '-5%' : '0')};
//       }
//     }
//   }
//   &.price-range {
//     .price,
//     .old-price,
//     .new-price {
//       display: flex;
//       align-items: flex-end;
//       &::before {
//         display: inline-block;
//         content: ${props => props.fromText};
//         margin-right: 3px;
//         padding-bottom: 5px;
//         font-size: 1rem;
//         font-family: ${theme.fonts.primary};
//       }
//     }
//     .old-price {
//       padding-bottom: 4px;
//       &::before {
//         padding-bottom: 0px;
//       }
//     }
//   }
// `;

// const AvailabilityWrapper = styled('div')`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 1rem;
//   margin-bottom: 1rem;
//   .location {
//     position: relative;
//     display: flex;
//     p {
//       margin-left: 5px;
//       font-size: 0.8rem;
//       color: #878787;
//     }
//   }
//   @media screen and (max-width: 430px) {
//     flex-direction: column;
//     gap: 0.5rem;
//   }
// `;

// const Dot = styled('div')`
//   height: 8px;
//   width: 8px;
//   border-radius: 50%;
//   background-color: ${props => (props.backgroundColor ? '#4cd038' : '#b13827')};
// `;

// const Checkmark = styled('div')`
//   transform: rotate(45deg);
//   height: 11px;
//   width: 6px;
//   border-bottom: 2px solid #4cd038;
//   border-right: 2px solid #4cd038;
//   margin-right: 1px;
//   margin-top: 2px;
// `;

// const Cross = styled('div')`
//   position: absolute;
//   right: ${props => props.right};
//   top: 4px;
//   width: 0px;
//   height: 8px;

//   :before, :after {
//     position: absolute;
//     left: 0px;
//     content: ' ';
//     height: 10px;
//     width: 2px;
//     background-color: #b13827;
//   }
//   :before {
//     transform: rotate(45deg);
//   }
//   :after {
//     transform: rotate(-45deg);
//   }
// `;

// export const parsePosition = (position) => {
//   switch (position) {
//     case 'Överkant - Höger':
//       return {
//         top: 0,
//         right: 0,
//         display: 'flex',
//         alignItems: 'flex-start',
//         justifyContent: 'flex-end'
//       };
//     case 'Överkant - Vänster':
//       return {
//         top: 0,
//         left: 0,
//         display: 'flex',
//         alignItems: 'flex-start',
//         justifyContent: 'flex-end'
//       };
//     case 'Nederkant - Höger':
//       return {
//         bottom: 0,
//         right: 0,
//         display: 'flex',
//         alignItems: 'flex-end',
//         justifyContent: 'flex-end'
//       };
//     case 'Nederkant - Vänster':
//       return {
//         bottom: 3,
//         left: 3,
//         display: 'flex',
//         alignItems: 'flex-end',
//         justifyContent: 'flex-end'
//       };
//     default:
//       return {
//         top: 0,
//         right: 0
//       };
//   }

// }

export const getPercentage = product => {
  const divided = product?.price?.incVat / product?.previousPrice?.incVat;
  const fixed = divided.toFixed(2);
  const fixedPercentage = Math.floor((1 - fixed) * 100);
  return fixedPercentage;
};

export const getTemplateName = product => {
  const marketingCategoryObject = product?.customFields?.find(
    item => item.key === 'MarketingCategory'
  );

  // Check if the object exists and the "listValues" array is not empty
  if (
    marketingCategoryObject &&
    marketingCategoryObject?.listValues?.length > 0
  ) {
    // Get the first value from the "listValues" array
    const firstMarketingCategoryValue = marketingCategoryObject?.listValues[0];
    return firstMarketingCategoryValue;
  } else {
    return null;
  }
};

export const getMatchingTemplates = (product, templates) => {
  const marketingCategoryObject = product?.customFields?.find(
    item => item.key === 'MarketingCategory'
  );
  // Check if the object exists and the "listValues" array is not empty
  if (
    marketingCategoryObject &&
    marketingCategoryObject?.listValues?.length > 0
  ) {
    // Get the first value from the "listValues" array
    const marketingCategories = marketingCategoryObject?.listValues;
    const matchingTemplates = Object.keys(templates)?.filter(key => {
      return marketingCategories.includes(key);
    });
    return matchingTemplates;
  } else {
    return null;
  }
};

const getHighResSymbol = productTemplate => {
  const highResSymbols = [
    { wow: wow },
    { clubHooks: clubHooks },
    { member: member },
    { newStandard: newStandard },
    { outlet: outlet },
    { helmet: newStandard }
  ];
  if (!productTemplate?.symbol) {
    return null;
  }
  for (const symbol of highResSymbols) {
    const key = Object.keys(symbol)[0];
    if (productTemplate?.symbol.toLowerCase().includes(key.toLowerCase())) {
      return symbol[key];
    }
  }
  return productTemplate?.symbol;
};

// const Availability = ({ isBuyable, hasStockInStore }) => {
//   const { selectedChannel } = useContext(ChannelContext);
//   const isNorway = selectedChannel?.language?.culture === 'nb-NO';
//   const isFinland = selectedChannel?.language?.culture === 'fi-FI';
//   const hasNoStores = selectedChannel?.id === 2 || selectedChannel?.id === 6;
//   return (
//     <AvailabilityWrapper>
//         <div className="location">
//           {isBuyable ? <Checkmark /> : <Cross right={isNorway ? "67px" : isFinland ? "34px" :  "62px"} />}
//           <p>{t("Webshop")}</p>
//         </div>
//         {!hasNoStores && (
//         <div className="location">
//           {hasStockInStore ? <Checkmark /> : <Cross right={isNorway ? "45px" : isFinland ? "59px" :  "37px"} />}
//           <p>{t("Store")}</p>
//         </div>
//         )}
//     </AvailabilityWrapper>
//   );
// }

// const checkStockLevels = (product) => {
//   const checkVariants = () => {
//     const variants = product?.variants?.values;
//     if (variants?.length > 0) {
//       const warehouseStock = variants?.map(variant => variant.warehouseStock);
//       const hasStock = warehouseStock?.map(location => location?.some(location => location.stockLevel > 0)).some(hasStock => hasStock === true);
//       return hasStock;
//     }
//     else { return false }
//   }
//   const singleHasStock = product?.warehouseStock?.some(location => location.stockLevel > 0);
//   const variantsHasStock = checkVariants();
//   if (singleHasStock || variantsHasStock) {
//     return true;
//   } else { return false }
// }

// export const ProductCardWithStockStatus = ({product}) => {

//   const { data, loading ,error } = useQuery(StockStatusQuery, {
//     variables: {
//       articleNumber: product?.articleNumber
//     }
//   });

//   const newProduct = {
//     ...product,
//     stockStatus: data?.product?.stockStatus
//   }

//   return (
//     <StyledProductCard product={newProduct} />
//   )
// }

export function StyledProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  loading,
  ...linkProps
}) {
  const hasNoPrice = !product?.price?.incVat === 0;
  //   const isBuyable = product?.stockStatus?.buyable;
  //   const hasStockInStore = checkStockLevels(product);
  //   const { culture } = selectedChannel.language;

  const { loggedIn } = useAuth();
  const hasMemberPrice = product?.hasMemberPrice;
  const productToUse = useProductPricing(product, hasMemberPrice, loggedIn);

  const fixedPercentage = getPercentage(product);
  const { selectedChannel = {} } = useContext(ChannelContext);
  const isNorway = selectedChannel?.id === 4;
  const hasImages = product.images && product.images.length > 0;
  const { productTemplates, saleTemplate } = useGlobalSettings();
  const isSale = isSaleProduct(product);
  const templates = getMatchingTemplates(product, productTemplates);
  const productTemplate = productTemplates[templates?.[0]] ?? null;
  const hasHeight = productTemplate?.symbolHeight !== null;
  const hasPriceDiffer = usePriceDiffer({
    variants: product?.variants?.values
  });
  let uniqueBadges = badgePriority(product.badges, 'name');
  const productBrand =
    product?.name?.split(' ')[product?.name?.split(' ').length - 1];
  const productName = product?.name?.split(' ').slice(0, -1).join(' ');

  const altHeart =
    productTemplate?.symbol &&
    productTemplate?.symbolPosition === 'Överkant - Höger'
      ? true
      : false;

  if (!loggedIn) {
    uniqueBadges = uniqueBadges?.filter(badge => badge?.name !== 'Medlem');
  }
  let fromText = 'Fr.';

  const saleBadges = [
    ...product?.badges,
    {
      name: 'SaleSpecial',
      url: null,
      location: 'TOP_LEFT',
      style: saleTemplate.tagClass,
      text: saleTemplate.tagText
    }
  ];
  const hasSaleTemplate = Object.keys(saleTemplate).length > 0;

  // Visibility settings for productTemplate
  const countryVisibilitySettings = {
    sweden: productTemplate?.sweden,
    denmark: productTemplate?.denmark,
    norway: productTemplate?.norway,
    finland: productTemplate?.finland,
    netherlands: productTemplate?.netherlands,
    eu: productTemplate?.eu,
    loggedIn: productTemplate?.loggedIn,
    loggedOut: productTemplate?.loggedOut
  };
  const doNotRender = useVisibilitySettings(countryVisibilitySettings);

  const symbolPath = getHighResSymbol(productTemplate);

  if (hasNoPrice) {
    return null;
  }
  return (
    <Wrapper
      data-testid="product"
      tmplt={productTemplate}
      className={cx(
        'product-card-wrapper',
        className,
        productTemplate && 'templatedProduct'
      )}
    >
      <ProductLink
        product={product}
        loading={loading ? loading : undefined}
        {...linkProps}
      >
        <ImageWrapper>
          {productTemplate && productTemplate?.symbol && !doNotRender && (
            <ProductSymbolWrapper
              symbolSize={'44px'}
              symbolHeight={
                hasHeight
                  ? productTemplate?.symbolHeight + 'px'
                  : productTemplate?.symbolSize + 'px'
              }
              //   style={parsePosition(productTemplate?.symbolPosition)}
            >
              <Image
                className="product-symbol"
                sizes={'100'}
                aspect={'1:1'}
                alt={productTemplate?.symbolText ?? ''}
                title={productTemplate?.symbolText ?? ''}
                src={symbolPath}
                fillAspect={true}
              />
            </ProductSymbolWrapper>
          )}
          <AddFavourite
            className="add-fav"
            product={product}
            style={{
              float: 'right',
              marginLeft: 'auto',
              fontSize: '1.5em',
              marginTop: altHeart
                ? `calc(1.5rem + ${productTemplate?.symbolSize}px)`
                : '0.5rem'
            }}
          />

          {hasImages ? (
            <>
              <div className="badges-wrapper">
                <Badges
                  badges={isSale && hasSaleTemplate ? saleBadges : uniqueBadges}
                />
              </div>
              {fixedPercentage > 0 && (
                <div className="percentage test">-{fixedPercentage}%</div>
              )}
              <Image
                className={cx(
                  product.images.length > 1 ? 'main-image' : 'only-image'
                )}
                sizes={imageSizes}
                aspect={imageAspect}
                alt={product.images[0].alt}
                src={product.images[0].url}
                modifiedDate={product.images[0].modifiedDate}
                critical={true}
              />
              {product?.images?.length > 1 && (
                <Above breakpoint="md">
                  {matches => (
                    <Image
                      className="show-on-hover"
                      sizes={imageSizes}
                      aspect={imageAspect}
                      alt={product?.images[1]?.alt}
                      src={product?.images[1]?.url}
                      modifiedDate={product?.images[1]?.modifiedDate}
                    />
                  )}
                </Above>
              )}
            </>
          ) : (
            <Image aspect={imageAspect} />
          )}
        </ImageWrapper>

        <section className="product-card-detail-container">
          {/* <Availability
            isBuyable={isBuyable}
            hasStockInStore={hasStockInStore}
          /> */}
          <header>
            <h4 className="brand-name">{productBrand}</h4>
            <h3 className="product-name">{productName}</h3>
          </header>
          <Intl>
            {t => (
              <PriceWrapper
                fromText={fromText}
                className={hasPriceDiffer ? 'price-range' : 'single-price'}
                isNorway={isNorway}
              >
                <Price
                  hasMemberPrice={hasMemberPrice}
                  price={productToUse?.price}
                  previousPrice={productToUse?.previousPrice}
                  fromPrice={hasPriceDiffer}
                ></Price>
              </PriceWrapper>
            )}
          </Intl>
          <div
            className="gmf-product-rating"
            data-compact
            data-product-id={product?.articleNumber}
          ></div>
        </section>
      </ProductLink>
    </Wrapper>
  );
}
