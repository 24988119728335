import React from 'react'
import { styled } from 'linaria/react';
import { ReactComponent as Check } from '../../svg/Check.svg';
import { theme } from '../Theme';

const UspList = styled('ul')`
    list-style-type: none;
`;

const UspItem = styled('li')`
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    line-height: 20px;
    color: ${theme.colors.primary};
    margin-bottom: 3px;
    position: relative;
    padding-left: 1.5rem;
    svg {
        position: absolute;
        left: 0;
        top: 2px;
        width: .9rem;
        height: .9rem;
        fill: black !important;
        path {
            fill: black !important;
        }
    }
`;

const ListOfUsps = ({ uspList }) => {
    return (
    <UspList>
        {uspList.map((usp, index) => (
            <UspItem key={index}>
                <Check />
                {usp}
            </UspItem>
        ))}
    </UspList>
    )
}

export default ListOfUsps;