import t from '@jetshop/intl';
import { theme } from '../../Theme';
import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import { ProductCard } from '../../CategoryPage/ProductCard';
import ProductCarousel from '../../ui/ProductCarousel';
import CartRecommendationsQuery from './CartRecommendationsQuery.gql';
import { RefinedProductCard } from '../../CategoryPage/RefinedProductCard';

const Wrapper = styled('div')`
  max-width: 90rem;
  margin: auto;
  padding: 0 0 0 10px;
  ${theme.below.lg} {
  }

  h2 {
    text-align: center;
    padding-right: 10px;
    margin: auto;
    font-size: 1rem;
    letter-spacing: 0;
    text-transform: none;
    margin-bottom: 10px;
  }

  .slide-nav {
    height: 35px;
    width: 35px;
    line-height: 35px;
    svg {
      width: 15px;
      height: 15px;
    }
    &.left {
      left: 5px;
    }
    &.right {
      right: 18px;
    }
  }

  li.product-card {
    .badges-wrapper,
    .add-fav {
      display: none;
    }
    .product-card-detail {
      padding-left: 0;
      padding-right: 5px;
      h3 {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: 0;
      }

      .single-price {
        justify-content: flex-start;
        margin: 0;
        min-height: 22px;
        > div {
          align-items: center;
          width: 100%;
        }
        .price {
          font-size: 0.9rem;
          padding-bottom: 1px;
        }
        .new-price {
          font-size: 0.9rem;
        }
        .old-price {
          font-size: 12px;
          margin: 0 5px 0 0;
          padding: 0;
        }
      }

      .currency {
        margin-bottom: 0px;
        
    }
  }
`;

const CartRecommendations = ({ whenClicked }) => {
  const { data, loading, error } = useQuery(CartRecommendationsQuery, {
    variables: {
      id: 2415
    }
  });
  if (error || loading) return null;

  return (
    <>
      <Wrapper onClick={whenClicked}>
        {data?.category?.subcategories?.length > 0 ? (
          <>
            <h2>{data?.category?.mainHeader}</h2>
            <ProductCarousel
              products={data?.category?.subcategories[0].products.result.filter(
                product => product.stockStatus.buyable
              )}
              listName={'Cart recoms'}
              loading={loading}
              ProductComponent={RefinedProductCard}
              toShow={2}
              productWidth={50}
            />
          </>
        ) : null}
      </Wrapper>
    </>
  );
};
export default CartRecommendations;
