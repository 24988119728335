import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from "linaria/react";

import { theme } from "../../Theme";
import { Link } from 'react-router-dom';

import MaxWidth from '../MaxWidth';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';
import FooterQuery from './FooterQuery.gql';
import InspirationQuery from './InspirationQuery.gql';

import { ReactComponent as InstagramIcon } from '../../../svg/Instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../svg/Linkedin.svg';
import { ReactComponent as FacebookIcon } from '../../../svg/Facebook.svg';
import FooterLinks from './FooterLinks';
import { ClubHooksFooter } from './ClubHooksFooter';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import klarna from './footer-logos/klarna-1.png';
import postnord from './footer-logos/POSTNORD.png';
import trygg from './footer-logos/trygg_e-handel_hanglas_300.png';
import dkehandel from './footer-logos/DKehandel.png';
import visa from './footer-logos/VISA.png';
import mastercard from './footer-logos/mastercard.png';
import notrygg from './footer-logos/no-trygg-ehandel.png';
import posti from './footer-logos/posti.png';
import asml from './footer-logos/asml.png';
import ups from './footer-logos/ups.png';
import { useIntl } from '@jetshop/intl';
import { useLocation } from 'react-router';
import { Above } from '@jetshop/ui/Breakpoints';



const FooterWrapper = styled('footer')`
  display: grid;
  grid-template-columns: ${props => props.hideClubHooks ? 'repeat(6 1fr)' : 'repeat(3, minmax(120px, 1fr)) minmax(300px, 400px) minmax(140px, 200px) minmax(140px, 200px) repeat(3, 1fr)'};
  grid-template-rows: 55% 1fr 1fr 1fr;
  background: ${theme.colors.primary};

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ${props => props.hideClubHooks ? 'repeat(4, auto)' : 'repeat(4, .5fr) repeat(4, auto)'};
   
  }

  h2 {
    font-size: 14px;
    text-transform: uppercase;
  }
  p, a {
    font-size: 12px;
  }

  /* > * {
    border: 1px solid blue;
  } */
  // Sections layout and styling
  .club-hooks {
    grid-area: 1 / 1 / 6 / 4;
    ${theme.below.lg} {
      grid-area: 1 / 1 / 4 / 3;
    }
  }

  .footer-links {
    padding-top: clamp(1rem, 2.5vw, 8rem);
    padding-left: clamp(1rem, 2vw, 8rem);
    grid-area: ${props => props.hideClubHooks ? '1 / 3 / 2 / 4' : '1 / 4 / 2 / 5'};
    ${theme.below.lg} {
      grid-area: 4 / 1 / 5 / 3;
      width: clamp(200px, 100%, 300px);
      margin: auto;
      margin-bottom: 1rem;
      padding-top: clamp(1rem, 4vw, 8rem);
    }
    a {
      color: white;
      text-decoration: none;
        &:hover {
        text-decoration: underline;
      }
    }
  }

  .inspiration {
    padding-top: clamp(1rem, 2.5vw, 8rem);

    grid-area: ${props => props.hideClubHooks ? '1 / 5 / 2 / 6' : '1 / 5 / 2 / 6'}  ;
    ${theme.below.lg} {
      grid-area: 5 / 1 / 6 / 2;
      justify-self: flex-end;
      margin-right: 2.7rem;
    }
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .address-info {
    padding-top: clamp(1rem, 2.5vw, 8rem);
    grid-area: ${props => props.hideClubHooks ? '1 / 7 / 2 / 8' : '1 / 6 / 2 / 7'};
    ${theme.below.lg} {
      grid-area: 5 / 2 / 6 / 3;
      margin-left: .8rem;
      max-width: 140px;
    }
    h2 {
      margin-bottom: 5px;
    }
  }

  .newsletter {
    grid-area: 2 / 4 / 3 / 6;
    padding: 0 0 1.5rem 2rem;
    ${theme.below.lg} {
      grid-area: 6 / 1 / 7 / 3;
      padding: 1rem 0 1.5rem 0;
    }
  }

  .socials {
    justify-self: end;
    grid-area: 2 / 1 / 3 / 10;
    align-self: center;
    ${theme.below.lg} {
      grid-area: 7 / 1 / 8 / 3;
      justify-self: center;
    }
  }

  .channel-info-images {
    margin-top: 1rem;
    grid-area: 3 / 1 / 4 / 10;
    ${theme.below.lg} {
      grid-area: 8 / 1 / 9 / 3;
    }
  }

  .bottom {
    grid-area: 4 / 1 / 5 / 10;
    ${theme.below.lg} {
      grid-area: 9 / 1 / 10 / 3;
    }
  }

  .divider-white {
    border-top: 1px solid white;
    grid-area: ${props => props.hideClubHooks ? '3 / 1 / 3 / 10' : '3 / 4 / 3 / 10'};
    ${theme.below.lg} {
      grid-area: 7 / 1 / 7 / 3;
      margin: 0 1.5rem;
    }
  }
  .divider-black {
    border-top: 1px solid black;
    grid-area: 3 / 1 / 3 / 4;
    ${theme.below.lg} {
      grid-area: 9 / 1 / 9 / 3;
    }
  }
`;

//styled under 'FooterWrapper'
const Divider = styled('div')``;

// For sections that need Flexbox layout internally
const FooterSection = styled('div')`
  display: flex;
  flex-direction: column;
  color: ${props => props.color || 'white'};
  background: ${props => props.color === 'black' ? theme.colors.background : 'transparent'};
`;

const BottomRow = styled('div')`
  text-align: center;
  color: white;
  padding: 10px 10px 20px 10px;
  font-size: 12px;
  p {
    font-size: 12px;
  }
`;

const SocialLink = styled('a')`
  display: block;
  svg {
    width: 28px;
    height: 28px;
  }
`;

const SocialLinkWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap:12px;
  padding: 16px 20px;
`;

const ChannelInfoImages = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100px !important;
    height: auto !important;
    max-height: 40px;
    margin: 0 4px;
  }

  svg {
    max-width: 100px !important;
    min-width: 70px;
    height: auto !important;
    max-height: 40px;
    margin: 0 4px;
  }
`;

export function BringLogo() {
  return (
    <svg viewBox="0 0 135 51" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          className="logo-part-1"
          d="M89.174 21.286h4.58v-4.524h-4.58v4.524zm0 21.286h4.58V23.06h-4.58v19.512zm36.02-4.083c-3.405 0-4.095-1.243-4.095-5.806 0-4.564.69-5.965 4.094-5.965 3.933 0 4.988 1.08 4.988 5.964 0 4.883-1.055 5.805-4.988 5.805zm5.392-15.43v1.538c-1.54-1.36-4.053-1.882-6.162-1.882-6.81 0-7.905 4.044-7.905 9.968 0 4.684.445 9.888 7.945 9.888 1.905 0 4.256-.44 5.676-1.723 0 1 .042 2.41-.08 3.41-.244 1.84-2.15 2.642-4.784 2.642-2.555 0-3.362-.712-3.362-2.113h-4.99c0 4.964 4.5 5.955 8.472 5.955 3.65 0 8.435-.96 9.164-5.604.162-1.08.162-2.848.162-4.65V23.06h-4.136zm-24.17-.344c-2.796 0-4.58.88-5.715 1.882v-1.54H96.73v19.514h4.58v-9.088c0-3.402-.363-6.765 4.582-6.765 3.975 0 3.61 1.8 3.61 5.564V42.57h4.58V31.92c0-4.24.326-9.206-7.663-9.206zm-25.844 0c-2.312 0-4.136.72-5.434 2.083v-1.74h-3.972V42.57h4.58v-8.487c0-1.52-.08-3.682.448-5.084.485-1.32 1.662-2.32 3.567-2.32 1.907 0 2.663.4 2.663 2.202h4.554c0-4.284-2.148-6.166-6.405-6.166zM60.076 38.81c-3.934 0-5.07-1.082-5.07-5.965 0-4.886 1.136-5.965 5.07-5.965 3.405 0 4.134 1.4 4.134 5.965 0 4.563-.73 5.964-4.134 5.964zm1.012-16.094c-2.148 0-4.5.24-6 1.763v-7.73h-4.58V42.57h4.133v-1.56c1.218 1.08 3.084 1.88 5.72 1.88 7.904 0 8.43-5.282 8.43-10.045 0-5.925-.93-10.13-7.702-10.13z"
          fill="#7bc144"
        />
        <path
          className="logo-part-2"
          d="M13.003 33.26c-5.9 0-10.732-4.493-11.19-10.2H0c.472 11.834 10.333 21.286 22.435 21.286a22.5 22.5 0 0018.922-10.234A21.867 21.867 0 0044.87 23.06H24.192c-.458 5.707-5.29 10.2-11.19 10.2z"
          fill="#C1C1C1"
        />
        <path
          className="logo-part-3"
          d="M1.813 21.285c.458-5.707 5.29-10.2 11.19-10.2 5.898 0 10.73 4.493 11.19 10.2H44.87C44.397 9.45 34.536 0 22.435 0 10.333 0 .473 9.45 0 21.285h1.813"
          fill="#7bc144"
        />
      </g>
    </svg>
  );
}

const Socials = () => {
  const {selectedChannel,channels} = useContext(ChannelContext);

  const fbLinks = {
    'SV':'https://www.facebook.com/hookssverige',
    'NO':'https://www.facebook.com/hooksnorge',
    'DK':'https://www.facebook.com/hooksdanmark',
    'FI':'https://www.facebook.com/hookssuomi',
    'EU':'https://www.facebook.com/hookseurope',
    'NL':'https://www.facebook.com/hooksnederland'
  }

  return(
  <>
    <SocialLinkWrapper>
      <SocialLink
        href="https://www.instagram.com/hooksofficial/"
        target="_blank"
      >
        <InstagramIcon />
      </SocialLink>
      <SocialLink href={fbLinks[selectedChannel.name]} target="_blank">
        <FacebookIcon />
      </SocialLink>
      <SocialLink
        href="https://www.linkedin.com/company/h%C3%B6%C3%B6ks-h%C3%A4stsport"
        target="_blank"
      >
        <LinkedinIcon />
      </SocialLink>
    </SocialLinkWrapper>
  </>
)};

const Bottom = () => (
  <BottomRow>
    © Hööks.se 2020
   
  </BottomRow>
);

const Inspiration = () => {
  const { loading, data, error } = useQuery(InspirationQuery);
  const t = useIntl();
  if (loading) return null;
  if (error) return `Error!: ${error}`;
  return (
    <>
      <h2>{t('Inspiration')}</h2>
      <ul>
        <li>
          {data.category && data.category.primaryRoute && (
            <Link to={data.category.primaryRoute.path}>
              {data.category.name}
            </Link>
          )}
        </li>
        <li>
          {data.page && data.page.primaryRoute && (
            <Link to={data.page.primaryRoute.path}>{data.page.name}</Link>
          )}
        </li>
      </ul>
    </>
  );
};



const AddressInfo = () => {
  const id = 1961;
  const { loading, data, error } = useQuery(FooterQuery, {
    variables: { id: id },
  });
  if (loading) return null;
  if (error) return `Error!: ${error}`;
  return (
    <>
      {data.category && data.category.content ? (
        <div
          dangerouslySetInnerHTML={{
            __html: data.category.content,
          }}
        />
      ) : null}
    </>
  );
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const location = useLocation();
  const hideClubHooks = selectedChannel?.name === 'EU';

  const getChannelLogos = () => {
    switch (selectedChannel.name) {
      case 'SV':
        return (
          <>
            <img src={klarna} />
            <img src={trygg} />
            <img src={postnord} />
          </>
        );

      case 'DK':
        return (
          <>
            <img src={klarna} />
            <img src={dkehandel} />
            <img className="post" src={postnord} />
          </>
        );

      case 'NO':
        return (
          <>
            <img src={klarna} />
            <img src={notrygg} />
            <BringLogo />
          </>
        );

      case 'FI':
        return (
          <>
            <img src={klarna} />
            <img src={posti} />
            <img src={asml} />
          </>
        );

      case 'EU':
        return (
          <>
            <img className="visa" src={visa} />
            <img className="mastercard" src={mastercard} />
            <BringLogo />
            <img className="ups" src={ups} />
          </>
        );

      default:
        break;
    }
  };

  return (
    <FooterWrapper hideClubHooks={hideClubHooks}>
      {!(hideClubHooks && selectedChannel?.name === 'EU') && (
        <FooterSection className='club-hooks' color='black'>
          <ClubHooksFooter />
        </FooterSection>
      )}
      
      {hideClubHooks && selectedChannel?.name !== 'EU' && (
        <Above breakpoint="lg">
          {matches => matches && (
            <FooterSection className='club-hooks' color='black'>
              <ClubHooksFooter />
            </FooterSection>
          )}
        </Above>
      )}

      <FooterSection className='footer-links'>
        <FooterLinks />
      </FooterSection>

      <FooterSection className='inspiration'>
        <Inspiration />
      </FooterSection>

      <FooterSection className='address-info'>
        <AddressInfo />
      </FooterSection>

      {selectedChannel?.name !== 'EU' && (
        <FooterSection className='newsletter'>
          <NewsletterField />
        </FooterSection>
      )}
      
      <FooterSection className='socials'>
        <Socials />
      </FooterSection>

      <Divider className='divider-black' />
      <Divider className='divider-white' />

      <FooterSection className='channel-info-images'>
        <ChannelInfoImages>{getChannelLogos()}</ChannelInfoImages>
      </FooterSection>
      
      <FooterSection className='bottom'>
        <Bottom />
      </FooterSection>

    </FooterWrapper>
  );
};

export default Footer;
