module.exports = {
  default: {
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      grey: '#878787',
      lightgrey: '#E8E8E8',
      tablegrey: '#F3F3F3',
      beige: '#f5f5dc',
      loadingBar: '#000000',
      blue: '#000000',
      red: '#b13827',
      background: '#f7f7f7',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      orange: '#ef7622',
      primary: '#000',
      accent: '#235837',
      accent2: '#C6979F',
      lightgreen: '#80937B',
      lightergreen: '#C9CEC6',
      clubhooksgreen: '#1A342D',
      christmasred: '#A81815',
      pink: '#E63787',
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '90rem'
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: '"Figtree", Helvetica, Arial, sans-serif',
      body: '"Figtree", Helvetica, Arial, sans-serif'
    }
  }
};
