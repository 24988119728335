export const useProductPricing = (product, hasMemberPrice, loggedIn) => {
  const hasVariants = product?.variants?.values?.length > 0;
  let updatedProduct = { ...product };
  if (!loggedIn && hasMemberPrice) {
    // Update the main product price
    updatedProduct = {
      ...updatedProduct,
      price: product?.memberPrice,
      previousPrice: product?.price,
    };

    // USE TO UPDATE PRICE FOR EACH VARIANT HERE
    // if (hasVariants) {
    //   updatedProduct.variants = {
    //     ...product.variants,
    //     values: product.variants.values.map(variant => ({
    //       ...variant,
    //       price: variant.memberPrice,
    //       previousPrice: variant.price,
    //     })),
    //   };
    // }
    return updatedProduct;
  }
  return product;
};