import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Intl } from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { Price } from '../Price';
import { AddFavourite } from '../ProductList/AddFavourite';
import { usePriceDiffer } from '../ProductPage/usePriceDiffer';
import { theme } from '../Theme';
import t from '@jetshop/intl';

import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';
import { badgePriority } from '../../utils/badgePriority';
import { useGlobalSettings } from '../Settings/GlobalSettings';
import { isSaleProduct } from '../ProductPage/ProductPage';
import { WarehouseStockQuery } from '../../components/ProductPage/WarehouseStockQuery.gql'
import { useQuery } from 'react-apollo';
import { useProductPricing } from '../../utils/useProductPricing';


const Wrapper = styled('li')`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-card-detail {
    background: white;
    padding: 0.75em;
    line-height: 1.35;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-weight: 500;
      font-size: 15px;
      min-height: 41px;
      white-space: wrap;
      overflow: hidden;
      color: ${theme.colors.primary};

      ${theme.below.sm} {
        font-size: 14px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 11px;
      color: ${theme.colors.mediumgrey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
    }
  }

  .badges-wrapper {
    & > div > div {
      top: 10px;
      div {
        margin-bottom: 5px;
      }
    }
    > div > div > div {
      font-weight: bold;
      font-size: 0.55rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1.1;
      text-align: center;
      width: 65px;
      height: 25px;
      color: white;
      background: #235837;
      ${theme.below.md} {
        height: 20px;
        font-size: 0.5rem;
        padding-top: 1px;
      }

      &.sale-tagg {
        background: #872C2B;
      }

      &.member-tagg {
        background: #b08832;
      }

      &.best-price-tagg {
        background: #ef7622;
      }

      &.new-tagg {
        background: #000;
      }

      &.member-deal-tagg {
        background: ${theme.colors.pink};
      }
    }
  }


`;

const SPECIAL = `
  &.templatedProduct {
    border: ${props => (props?.tmplt?.showBorder ? '1' : '0')}px solid
      ${props => props?.tmplt?.borderColor};
    &,
    .product-card-detail {
      background: ${props => props?.tmplt?.backgroundColor ?? 'transparent'};
    }
    ${Price.Old},${Price.Normal} {
      font-size: ${props => props?.tmplt?.defaultPrice_size}px;
      color: ${props => props?.tmplt?.defaultPrice_color};
    }
  }
`;

export const ProductSymbolWrapper = styled('div')`
  position: absolute;
  width: calc(${props => props.symbolSize} + 14px);
  height: calc(${props => props.symbolSize} + 14px);
  z-index: 2;
  .product-symbol {
    padding-bottom: 0 !important;
    height: calc(${props => props.symbolSize} + 14px) !important;
    display:content;
    picture{
      display:content;
    }
    img{
      padding:0px!important;
      max-width:100%!important;
      max-height:100%!important;
      width:auto!important;
      height:auto!important;
      position:absolute!important;
    }
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.03);
    pointer-events: none;
    z-index: 1;
  }
  .show-on-hover {
    opacity: 0;
    position: absolute !important;
    top: 0;
  }
  .percentage {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background: white;
    color: #878787;
    border-radius: 1rem;
    padding: 2px 8px;
    font-size: 0.8rem;
    z-index: 3;
    font-weight: bold;
  }

  ${theme.above.md} {
    &:hover {
      .show-on-hover {
        opacity: 1;
      }
      .main-image {
        opacity: 0;
      }
    }
  }

  /* img {
    padding: 3px !important;
  } */
`;

const AddFavoriteButtonWrapper = styled('div')`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;

  ${theme.below.sm} {
    top: 10px;
    right: 10px;
    .favorite-icon svg {
      width: 20px;
    }
  }
`;

const PriceRange = styled('span')`
  font-size: 0.85rem;
  margin-right: 5px;
  line-height: 1;
`;

const PriceWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  ${theme.below.sm} {
    font-size: 14px;
    margin-top: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      margin-right: 10px;
      color: ${theme.colors.primary};
    }
    .new-price {
      color: #b13827;
      margin-right: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'Figtree';
      font-size: 1.3rem;
      line-height: unset;
    }
    .price {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'Figtree';
      font-size: 1.3rem;
      line-height: unset;
      margin-right: 0;
    }
    .old-price {
      color: ${theme.colors.grey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 5px;
      padding: 0;
      margin-top: 0;
      line-height: unset;
      font-weight: normal;
    }
    .member-price {
      color: ${theme.colors.pink};
      font-family: 'Figtree';
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
  &.price-range {
    .price,
    .old-price,
    .new-price {
      display: flex;
      align-items: flex-end;
      &::before {
        display: inline-block;
        content: ${props => props.fromText};
        margin-right: 3px;
        padding-bottom: 5px;
        font-size: 1rem;
        font-family: ${theme.fonts.primary};
      }
    }

    .old-price {
      padding-bottom: 4px;
      &::before {
        padding-bottom: 0px;
      }
    }
  }
`;

const AvailabilityWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  .location {
    position: relative;
    display: flex;
    p {
      margin-left: 5px;
      font-size: 0.8rem;
      color: #878787;
    }
  }
  @media screen and (max-width: 430px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const Dot = styled('div')`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${props => (props.backgroundColor ? '#4cd038' : '#b13827')};
`;

const Checkmark = styled('div')`
  transform: rotate(45deg);
  height: 11px;
  width: 6px;
  border-bottom: 2px solid #4cd038;
  border-right: 2px solid #4cd038;
  margin-right: 1px;
  margin-top: 2px;
`;

const Cross = styled('div')`
  position: absolute;
  right: ${props => props.right};
  top: 4px;
  width: 0px;
  height: 8px;

  :before, :after {
    position: absolute;
    left: 0px;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: #b13827;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`;


export const parsePosition = (position) => {
  switch (position) {
    case 'Överkant - Höger':
      return {
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      };
    case 'Överkant - Vänster':
      return {
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
      };
    case 'Nederkant - Höger':
      return {
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
      };
    case 'Nederkant - Vänster':
      return {
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
      };
    default:
      return {
        top: 0,
        right: 0
      };
  }

}

export const getPercentage = (product) => {
  const divided = product?.price?.incVat / product?.previousPrice?.incVat;
  const fixed = divided.toFixed(2);
  const fixedPercentage = Math.floor((1 - fixed) * 100);
  return fixedPercentage;
}

export const getTemplateName = (product) => {
  const marketingCategoryObject = product?.customFields?.find(
    item => item.key === 'MarketingCategory'
    );
    
    // Check if the object exists and the "listValues" array is not empty
    if (marketingCategoryObject && marketingCategoryObject?.listValues?.length > 0) {
      // Get the first value from the "listValues" array
      const firstMarketingCategoryValue = marketingCategoryObject?.listValues[0];
      return firstMarketingCategoryValue;
    } else {
      return null
    }
  }

export const getMatchingTemplates = (product,templates) => {
  const marketingCategoryObject = product?.customFields?.find(
    item => item.key === 'MarketingCategory'
  );
  // Check if the object exists and the "listValues" array is not empty
  if (
    marketingCategoryObject &&
    marketingCategoryObject?.listValues?.length > 0
  ) {
    // Get the first value from the "listValues" array
    const marketingCategories = marketingCategoryObject?.listValues;
    const matchingTemplates = Object.keys(templates)?.filter((key) => {
      return marketingCategories.includes(key);
    });
    return matchingTemplates;
  } else {
    return null;
  }
};

const Availability = ({ isBuyable, hasStockInStore }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const isNorway = selectedChannel?.language?.culture === 'nb-NO';
  const isFinland = selectedChannel?.language?.culture === 'fi-FI';
  const hasNoStores = selectedChannel?.id === 2 || selectedChannel?.id === 6;
  return (
    <AvailabilityWrapper>
        <div className="location">
          {isBuyable ? <Checkmark /> : <Cross right={isNorway ? "67px" : isFinland ? "34px" :  "62px"} />}
          <p>{t("Webshop")}</p>
        </div>
        {!hasNoStores && (
        <div className="location">
          {hasStockInStore ? <Checkmark /> : <Cross right={isNorway ? "45px" : isFinland ? "59px" :  "37px"} />}
          <p>{t("Store")}</p>
        </div>
        )}
    </AvailabilityWrapper>
  );
}

const checkStockLevels = (product) => {
  const checkVariants = () => {
    const variants = product?.variants?.values;
    if (variants?.length > 0) {
      const warehouseStock = variants?.map(variant => variant.warehouseStock);
      const hasStock = warehouseStock?.map(location => location?.some(location => location.stockLevel > 0)).some(hasStock => hasStock === true);      
      return hasStock;
    } 
    else { return false }
  }
  const singleHasStock = product?.warehouseStock?.some(location => location.stockLevel > 0);
  const variantsHasStock = checkVariants();
  if (singleHasStock || variantsHasStock) {
    return true;
  } else { return false }
}

// export const ProductCardWithStockStatus = ({product}) => {


//   const { data, loading ,error } = useQuery(StockStatusQuery, {
//     variables: {
//       articleNumber: product?.articleNumber
//     }
//   });

//   const newProduct = {
//     ...product,
//     stockStatus: data?.product?.stockStatus
//   }

//   return (
//     <ProductCard product={newProduct} />
//   )
// }

export const LargeProductCard = ({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  loading,
  optionalImage = null,
  ...linkProps
}) => {
  
  const isBuyable = product?.stockStatus?.buyable;
  const hasStockInStore = checkStockLevels(product);

  const imageSrc = optionalImage ? optionalImage : product?.images?.[0]?.url;

  const { selectedChannel = {} } = useContext(ChannelContext);
  const { culture } = selectedChannel?.language;
  //MEMBER PRICE
  const { loggedIn } = useAuth();
  const hasMemberPrice = product?.hasMemberPrice;
  const productToUse = useProductPricing(product, hasMemberPrice, loggedIn);

  const hasImages = product?.images && product?.images?.length > 0;
  const { productTemplates, saleTemplate } = useGlobalSettings();
  const isSale = isSaleProduct(product);
 const templates = getMatchingTemplates(product, productTemplates);
  const fixedPercentage = getPercentage(product);
  const productTemplate = productTemplates[templates?.[0]] ?? null;

  const hasPriceDiffer = usePriceDiffer({
    variants: product?.variants?.values
  });
  let uniqueBadges = badgePriority(product?.badges, 'name');

  const altHeart =
    productTemplate?.symbol &&
    productTemplate?.symbolPosition === 'Överkant - Höger'
      ? true
      : false;

  if (!loggedIn) {
    uniqueBadges = uniqueBadges?.filter(badge => badge?.name !== 'Medlem');
  }
  let fromText = 'Fr.';

  const saleBadges = [
    ...product?.badges,
    {
      name: 'SaleSpecial',
      url: null,
      location: 'TOP_LEFT',
      style: saleTemplate?.tagClass,
      text: saleTemplate?.tagText
    }
  ];
    const hasSaleTemplate = Object.keys(saleTemplate)?.length > 0;
  
  return (
    <Wrapper
      data-testid="product"
      tmplt={productTemplate}
      className={cx(
        'product-card',
        className,
        productTemplate && 'templatedProduct'
      )}
    >
      <ProductLink
        product={product}
        loading={loading ? loading : undefined}
        {...linkProps}
      >
        <ImageWrapper>
          {productTemplate && productTemplate?.symbol && (
            <ProductSymbolWrapper
              symbolSize={productTemplate?.symbolSize + 'px'}
              style={parsePosition(productTemplate?.symbolPosition)}
            >
              <Image
                className="product-symbol"
                sizes={'100'}
                aspect={'1:1'}
                alt={productTemplate?.symbolText ?? ''}
                title={productTemplate?.symbolText ?? ''}
                src={productTemplate?.symbol}
                fillAvailableSpace={true}
              />
            </ProductSymbolWrapper>
          )}
          <AddFavourite
            className="add-fav"
            product={product}
            style={{
              float: 'right',
              marginLeft: 'auto',
              fontSize: '1.5em',
              marginTop: altHeart
                ? `calc(1.5rem + ${productTemplate?.symbolSize}px)`
                : '0.5rem'
            }}
          />

          {hasImages ? (
            <Above breakpoint="md">
              {matches => (
            <>
              <div className="badges-wrapper">
                <Badges
                  badges={isSale && hasSaleTemplate ? saleBadges : uniqueBadges}
                />
              </div>
              {fixedPercentage > 0 && (
                <div className="percentage test">-{fixedPercentage}%</div>
              )}
              <Image
                className={cx(
                  product?.images?.length > 1 ? 'main-image' : 'only-image'
                )}
                sizes={imageSizes}
                aspect={imageAspect}
                alt={product?.images[0]?.alt}
                src={imageSrc}
                modifiedDate={product?.images[0]?.modifiedDate}
                critical={true}
                // fillAvailableSpace={matches ? true : false}
                // crop={matches? true : false}
              />
              {product?.images?.length > 1 && (
                <Above breakpoint="md">
                  {matches => (
                    <Image
                      className="show-on-hover"
                      sizes={imageSizes}
                      aspect={imageAspect}
                      alt={product?.images[1]?.alt}
                      src={product?.images[1]?.url}
                      modifiedDate={product?.images[1]?.modifiedDate}
                    />
                  )}
                </Above>
              )}
            </>
          )}
          </Above>
          ) : (
            <Image aspect={imageAspect} />
          )}
        </ImageWrapper>

        <section className="product-card-detail">
          <Availability
            isBuyable={isBuyable}
            hasStockInStore={hasStockInStore}
          />
          <header>
            <h3>{product?.name}</h3>
            {/* <h4 className="sub-name">{product.subName || '\u00A0'}</h4> */}
          </header>
          <div
            className="gmf-product-rating"
            data-compact
            data-product-id={product?.articleNumber}
          ></div>
          <Intl>
            {t => (
              <PriceWrapper
                fromText={fromText}
                className={hasPriceDiffer ? 'price-range' : 'single-price'}
              >
                <Price
                   hasMemberPrice={hasMemberPrice}
                   price={productToUse?.price}
                   previousPrice={productToUse?.previousPrice}
                  fromPrice={hasPriceDiffer}
                ></Price>
              </PriceWrapper>
            )}
          </Intl>
        </section>
      </ProductLink>
    </Wrapper>
  );
}
