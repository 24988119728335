import React from 'react'
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { ReactComponent as Logout } from '../../../svg/Lock.svg';
import TrendButton from '../../ui/Button';
import { theme } from '../../Theme';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Link } from 'react-router-dom';
import USPList from '../../ui/ListOfUsps';
import { useQuery } from 'react-apollo';
import UspListQuery from './UspListQuery.gql';
import { extractUspList } from '../../../utils/extractUSPList';

const ClubHooksWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75%;
    text-align: left;
    ${theme.below.lg} {
        height: 100%;
    }
`;

const InnerWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    max-width: 250px;
`;

const PreTitle = styled('div')`
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: ${theme.colors.primary};
    margin-bottom: 5px;
`;

const Title = styled('div')`
    font-family: ${theme.fonts.primary};
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    color: ${theme.colors.primary};
    margin-bottom: 1.2rem;
`;

const LinkList = styled('ul')`
    list-style-type: none;
`;

const LinkItem = styled('li')`
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    line-height: 20px;
    color: ${theme.colors.primary};
    margin-bottom: 3px;
    position: relative;
    svg {
        position: absolute;
        left: 0;
        top: 2px;
        width: .9rem;
        height: .9rem;
        fill: black !important;
        path {
            fill: black !important;
        }
    }
    .logout-icon {
        color: ${theme.colors.primary};
        fill: transparent !important;
        path {
            fill: transparent !important;
        }
    }

    &.link {
        padding-left: 0;
        margin-bottom: 4px;
        a {
            color: inherit;
            text-decoration: none;
            
            &:hover {
                font-weight: 700;
                text-decoration: underline;
                text-underline-offset: 3px;
                text-decoration-thickness: 1px;
                cursor: pointer;
            }
        }
    }
    &.logout {
        padding-left: 0;
        margin-bottom: 4px;
        
        button {
            background: none;
            border: none;
            text-decoration: none;
            margin-left: 1.2rem;
            &:hover {
                font-weight: 700;
                text-decoration: underline;
                text-underline-offset: 3px;
                text-decoration-thickness: 1px;
                cursor: pointer;
            }
        }
    }
`;

const ButtonWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    margin-top: 1rem;
    a {
        text-decoration: none;
        width: 100%;
    }
`;

const StyledTrendButton = styled(TrendButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.primary};
    color: white;
    font-size: 14px;
    height: 41px;
    min-width: 85px;
    border-radius: 3px;
    &.secondary {
        background: ${theme.colors.background};
        color: ${theme.colors.primary};
        font-size: 14px;
        height: 41px;
        min-width: 240px;
        border: 1px solid ${theme.colors.primary};
        border-radius: 3px;
        &:hover {
            background: ${theme.colors.primary};
            color: ${theme.colors.white};
        }
    }
`;

const clubRoutes = [
    {
        path: '/orders',
        name: t('Köphistorik'),
    },
    {
        path: '/my-pages',
        name: t('Erbjudanden'),
    },
    {
        path: '/bonus',
        name: t('Bonusar'),
    },
    {
        path: '/profile',
        name: t('Kunduppgifter'),
    }
];

const LoggedOutContent = () => {
    const { data, loading, error } = useQuery(UspListQuery, {
        variables: { id: 3643 },
    });
    const uspList = extractUspList(data);
    return (
        <>
            <PreTitle>{t('Become a member')}</PreTitle>
            <Title>{t('As a member you get even more benefits')}</Title>
            <USPList uspList={uspList} />
            <ButtonWrapper>
                <Link to={'/signup'}>
                <StyledTrendButton>{t('Become a member')}</StyledTrendButton>
                </Link>
                <Link to={'/login'}>
                    <StyledTrendButton className='secondary'>{t('Log in')}</StyledTrendButton>
                </Link>
            </ButtonWrapper>
        </>
    )
};

const LoggedInContent = ({ myPagesPath, logOut }) => {
  const mappedRoutes = clubRoutes.map(route => ({
    ...route,
    path: `${myPagesPath}${route.path}`
  }));

  return (
    <>
        {/* <PreTitle>{t('Club Hooks')}</PreTitle> */}
        <Title>{t('My pages')}</Title>
        <LinkList>
            {mappedRoutes.map((route, index) => (
            <LinkItem key={index} className='link'>
                <Link to={route.path}>{route.name}</Link>
            </LinkItem>
            ))}
            <LinkItem className='logout'>
                <Logout className='logout-icon' />
                <button onClick={logOut} aria-label='log out'>{t('Log out')}</button>
            </LinkItem>
        </LinkList>
        <ButtonWrapper>
            <Link to={myPagesPath}>
                <StyledTrendButton className='secondary'>{t('My pages')}</StyledTrendButton>
            </Link>
        </ButtonWrapper>
    </>
  );
};

export const ClubHooksFooter = () => {
  const { loggedIn, logOut } = useAuth();
  const { routes } = useShopConfig();
  const myPagesPath = routes?.myPages?.path;
  return (
    <ClubHooksWrapper>
        <InnerWrapper>
            {loggedIn ? <LoggedInContent myPagesPath={myPagesPath} logOut={logOut} /> : <LoggedOutContent />}
        </InnerWrapper>
    </ClubHooksWrapper>
  );
};

