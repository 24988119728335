import React from 'react';
import { useEffect } from 'react';
import { styled } from 'linaria/react';
import { useQuery,useLazyQuery } from 'react-apollo';

import { StyledProductCard } from '../../CategoryPage/StyledProductCard';
import ProductCarousel from '../../ui/ProductCarousel';
import BestSellerQuery from './BestSellerQuery.gql';
import { Inner } from '../../CategoryPage/CategoryHeader';
import Spinner from '../../ui/Spinner';
import { Above } from '@jetshop/ui/Breakpoints';

const TitleWrapper = styled('div')`
    text-align: center;
    margin: 0.5rem 0;
`;

const ProductGridWrapper = styled('div')`
    display: grid;
    grid-template-columns: repeat(${props => props.productsLength}, 1fr);
    gap: 1rem;
    max-width: 94rem;
    width: 100%;
    margin: auto;
    padding: 1rem 2rem;
    list-style: none;

    ${Inner} & {
        padding: 0rem 0rem;
    }
`;

const StyledProductRow = ({ typeOfRow, title, maxQty, category, ...rest }) => {
    const skipQuery = category?.value === null;
    const { loading, error, data } = useQuery(BestSellerQuery, {
        variables: { path: category?.value?.primaryRoute?.path,limit:maxQty?.value },
        skip: !category?.value?.primaryRoute?.path,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
  // changing the fetch policy to no-cache means that it wont cache the data to store
  // so the previous conflict with the query in the category page is avoided

    if (loading) return <Spinner/>;
    if (error) return null;
    if (!data && !skipQuery) return <div style={{textAlign: 'center', margin: '2rem 0'}}>No data available.</div>;

    let categoryProducts = data?.route?.object?.products?.result;
    categoryProducts = categoryProducts?.slice(0, parseInt(maxQty.value));
    const chosenProducts = rest?.children?.map(child => child?.props?.product?.value).filter(product => product != null);

    const renderedProducts = typeOfRow?.value.startsWith('1') ? categoryProducts : chosenProducts;


    return (
        <>
        <TitleWrapper>
            <h2>{title?.value}</h2>
        </TitleWrapper>
        <Above breakpoint="lg">
            {matches => matches ? (
                <ProductGridWrapper productsLength={renderedProducts.length}>
                    {renderedProducts.map((product, index) => (
                        <StyledProductCard key={index} product={product} />
                    ))}
                </ProductGridWrapper>
            ) : (
                <ProductCarousel
                    products={renderedProducts}
                    listName={title?.value}
                    categoryPath={category?.value?.primaryRoute}
                    loading={loading}
                    ProductComponent={StyledProductCard}
                />
            )}
        </Above>
        
        </>
    );
};

export default StyledProductRow;
