import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useState, useRef, useEffect } from 'react';
import { styled } from "linaria/react";
import t from '@jetshop/intl';
import getCategoriesByLevel from './__util__/getCategoriesByLevel';
import getCategoriesUnderParent from './__util__/getCategoriesUnderParent';
import hasChildCategories from './__util__/hasChildCategories';
import hasChildCategory from './__util__/hasChildCategory';
import { CloseButton } from '../MobileMenu';

import { ReactComponent as AngleRight } from '../../../../svg/fa/angle-right-light.svg';
import { ReactComponent as AngleLeft } from '../../../../svg/fa/angle-left-regular.svg';
import { ReactComponent as Close } from '../../../../svg/fa/close.svg';
import { cleanTextString } from '../../../CategoryPage/CategoryHeader';
import { useHistory } from 'react-router-dom';

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

const Wrapper = styled('div')`
  &.toplvl {
    padding: 0 1rem;
  }
  a {
    border-top: 1px solid #d2d2d2;
    display: block;
    padding: 0.5rem 0;
    position: relative;
    &.allbtn {
      font-weight: bold;
    }
    svg {
      position: absolute;
      right: 0rem;
      top: 45%;
      transform: translateY(-30%);
      max-width: 1.5rem;
    }
  }

  &:last-child a {
    border-bottom: 1px solid #d2d2d2;
  }
`;

const SubcategoryWrapper = styled('div')`
  padding: 1rem;
  background: white;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  transform: translateX(-100%);

  min-height: 100vh;
  max-height: 100vh;
  transition: transform 0.5s;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  &.open {
    width: 100%;
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    max-height: 9999px;
    a {
      background: white;
    }
  }
`;

const SubNavHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 50px;
  background: white;
  .close-btn {
    padding-right: 0;
  }
`;

const BackButton = styled('button')`
  background: none;
  margin-left: -0.4rem;
  &:focus {
    outline: none;
  }
  svg {
    width: 27px;
    height: 27px;
    fill: black !important;
  }
`;

const CategoryTitle = styled('span')`
  font-size: 1.3rem;
  font-weight: bold;
  align-self: center;
  background: white;
`;

const MobileCategories = ({
  categories,
  closeMenu,
  parentCategory = null,
  menuWrapper,
  setLocked,
  openCat,
  setOpenCat,
}) => {
  const thisSubMenu = useRef(null);
  const [openIndex, setOpenIndex] = useState(null);
  const history = useHistory();
  const categoryLevel = parentCategory
    ? [parentCategory]
    : getCategoriesByLevel(categories, 1);
  useEffect(
    (v) => {
      if (thisSubMenu.current && thisSubMenu.current.scrollHeight) {
        setLocked(
          thisSubMenu.current.scrollHeight < menuWrapper.current.scrollHeight
        );
      } else {
        setLocked(false);
      }
      if (openCat === null) {
        setOpenIndex(null);
      }
    },
    [openCat, menuWrapper, setLocked]
  );
  const cleanCategories = categoryLevel.filter(
    cat => !cat.name.startsWith('-')
  );

  return (
    <>
      {cleanCategories.map((category, i) => (
        <Wrapper
          className={`${!parentCategory ? 'toplvl' : 'sublvl'}`}
          key={category.id}
        >
          <CategoryLink
            className={`${!parentCategory ? 'toplvl' : 'sublvl'}`}
            onClick={e => {

              if(category.id === 155){
                history.push(category?.primaryRoute?.path);
                closeMenu();
              }
              // If this category has child categories, we want to open them up with `handleClick`
              // Otherwise, we want to follow the link and `closeMenu`
              if (hasChildCategories(categories, category)) {
                handleClickAndPreventDefault(
                  e,
                  () => {
                    setOpenCat(category.id);
                    setOpenIndex(i);
                    menuWrapper.current.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });
                  },
                  i
                );
              } else {
                closeMenu();
              }
            }}
            category={category}
          >
            {cleanTextString(category.name)}
            {hasChildCategories(categories, category) && <AngleRight />}
          </CategoryLink>
          {hasChildCategories(categories, category) && (
            <SubcategoryWrapper
              className={openIndex === i ? 'open' : null}
              innerRef={openCat === category.id ? thisSubMenu : null}
            >
              <SubNavHeader>
                <BackButton
                  onClick={() => {
                    setOpenCat(category.parentId);
                    setOpenIndex(category.parentId);
                    setLocked(false);
                  }}
                >
                  <AngleLeft />
                </BackButton>
                <CategoryTitle>{category.name}</CategoryTitle>
                <CloseButton
                  className="close-btn"
                  onClick={() => {
                    closeMenu();
                    setOpenCat(null);
                  }}
                >
                  <Close />
                </CloseButton>
              </SubNavHeader>
              {parentCategory && (
                <Wrapper>
                  <CategoryLink
                    onClick={() => {
                      closeMenu();
                      setOpenCat(null);
                    }}
                    className={'allbtn sublvl'}
                    category={category}
                  >
                    {t('See all in')} {category.name}
                  </CategoryLink>
                </Wrapper>
              )}
              <SubCategories
                setLocked={setLocked}
                menuWrapper={menuWrapper}
                categories={categories}
                category={category}
                closeMenu={closeMenu}
                setOpenCat={setOpenCat}
                openCat={openCat}
              />
            </SubcategoryWrapper>
          )}
        </Wrapper>
      ))}
    </>
  );
};

const SubCategories = ({
  categories,
  category,
  closeMenu,
  menuWrapper,
  setLocked,
  setOpenCat,
  openCat,
}) => {
  return getCategoriesUnderParent(categories, category.id).map(
    (childCat, i) => {
      if (childCat.name.startsWith('-')){
        return null
      }
        return hasChildCategories(categories, childCat) ? (
          <MobileCategories
            setLocked={setLocked}
            menuWrapper={menuWrapper}
            key={i}
            categories={categories}
            closeMenu={closeMenu}
            parentCategory={childCat}
            setOpenCat={setOpenCat}
            openCat={openCat}
          />
        ) : (
          <CategoryLink
            className="sublvl"
            key={i}
            onClick={() => {
              closeMenu();
              setOpenCat(null);
            }}
            category={childCat}
          >
            {childCat.name}
          </CategoryLink>
        );
    }
  );
};

export default MobileCategories;
