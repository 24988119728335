import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import pagePreviewQuery from './PagePreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import Return from './Pages/Return';

import GlobalTokenLogin from './Auth/Signup/PersonLookup/GlobalTokenLogin';
import { IEBumper } from './IEBumper';
import Popup from './Popup';
import { EuChannelOptions } from './Layout/Header/ChannelSelector/ChannelSelector';
import '../globalStyles';
import '@jetshop/ui/Theme/sanitizeCss';
import { VoyadoProvider } from '@jetshop/flight-voyado';

import CategoryPage from './CategoryPage/CategoryPage';
import { GlobalSettingsProvider } from './Settings/GlobalSettings';
import { GamifieraProvider } from './Gamifiera/GamifieraContext';

import ClubHooksPage from './MyPages/ClubHooksPage';


const Store = loadable(() => import('./Store/Store'), {
  fallback: LoadingPage
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: LoadingPage
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: LoadingPage
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: LoadingPage
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: LoadingPage
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: LoadingPage
});

// const LoadableHFPage = loadable(() => import('./MyPages/HooksFriendsPage'), {
//   fallback: LoadingPage,
// });

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: LoadingPage
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: LoadingPage
  }
);

const LoadableAcademyPage = loadable(() => import('./Academy/AcademyPage'), {
  fallback: LoadingPage
});

const LoadableSingleAcademy = loadable(
  () => import('./Academy/SingleAcademy'),
  {
    fallback: LoadingPage
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: LoadingPage
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: LoadingPage
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: LoadingPage
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: LoadingPage
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: LoadingPage
});

export const LoadableHFPage = loadable(
  () => import('./MyPages/HooksFriendsPage'),
  {
    fallback: LoadingPage
  }
);

export const LoadableClubHooksPage = loadable(
  () => import('./MyPages/ClubHooksPage'),
  {
    fallback: LoadingPage
  }
);

export const LoadableTermPage = loadable(() => import('./MyPages/TermPage'), {
  fallback: LoadingPage
});

const LoadableSignUpPage = loadable(
  () => import('./Auth/Voyado/VoyadoSignup'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableExpressSignUpPage = loadable(
  () => import('./Auth/Voyado/VoyadoExpressSignup'),
  {
    fallback: <LoadingPage />
  }
);

const VoyadoLookup = loadable(() => import('./Auth/Voyado/VoyadoLookup'), {
  fallback: <LoadingPage />
});



function Shop() {
  return (
    <GenericError>
      <ModalProvider>
        <Container>
          <LoadingBar />

          <Helmet titleTemplate="%s - Hööks" defaultTitle="Hööks" />
          <GamifieraProvider>
            <VoyadoProvider>
              <ProductListProvider queries={productListQueries}>
                <GlobalSettingsProvider>
                  <Header />

                  <Content>
                    <PaginationProvider defaultProductsPerPage={40}>
                      <GlobalTokenLogin>
                        <Switch>
                          <Route exact path="/" component={LoadableStartPage} />
                          <Route exact path="/retur/" component={Return} />
                          <Route
                            exact
                            path="/retur/:token"
                            component={Return}
                          />
                          <Route
                            path="/search"
                            component={LoadableSearchPage}
                          />
                          <Route
                            path="/signup"
                            component={LoadableSignUpPage}
                          />
                          <Route
                            path="/express-signup"
                            component={LoadableSignUpPage}
                          />
                          <Route path="/login" component={VoyadoLookup} />
                          <Route
                            path="/stores/:region/:slug"
                            component={StoreLocator}
                          />
                          <Route
                            path="/stores/:slug"
                            component={StoreLocator}
                          />
                          <Route path="/stores" component={StoreLocator} />

                          <Route path="/tree" component={NavTreePage} />
                          <Route path="/my-pages" component={LoadableMyPages} />
                          <Route
                            path={['/hooks-and-friends', '/hooks-friends']}
                            component={LoadableHFPage}
                          />
                          <Route
                            path={['/club-hooks', '/clubhooks', '/about-club-hooks', 'om-club-hooks', '/klubbhooks']}
                            component={LoadableClubHooksPage}
                          />
                          <Route
                            path={'/membership-agreement'}
                            component={LoadableTermPage}
                          />
                          <Route
                            path="/hooks-academy/:lvl2/:lvl3/:lvl4/:lvl5"
                            component={LoadableSingleAcademy}
                          />
                          <Route
                            path="/hooks-academy"
                            component={LoadableAcademyPage}
                          />
                          <Route
                            exact
                            path="/forgot-password"
                            component={ForgotPassword}
                          />
                          <Route
                            path="/reset-password/:token"
                            component={ResetPassword}
                          />
                          <Route
                            path="/preview"
                            render={props => (
                              <PreviewRoute
                                productPage={LoadableProductPage}
                                productQuery={ProductPreviewQuery}
                                pageQuery={pagePreviewQuery}
                                categoryQuery={CategoryPreviewQuery}
                                categoryPage={CategoryPage}
                                StartPage={LoadableStartPage}
                                {...props}
                              />
                            )}
                          />
                          <DynamicRoute
                            routeQuery={routeQuery}
                            productPage={LoadableProductPage}
                            categoryPage={LoadableCategoryPage}
                            contentPage={LoadableContentPage}
                            notFoundPage={NotFound}
                            LoadingPage={LoadingPage}
                          />
                        </Switch>
                      </GlobalTokenLogin>
                    </PaginationProvider>
                  </Content>
                  <Footer />
                </GlobalSettingsProvider>
              </ProductListProvider>
            </VoyadoProvider>
          </GamifieraProvider>
          <EuChannelOptions />
          <ModalRoot />
          <ScrollRestorationHandler
            ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
          />
        </Container>
      </ModalProvider>
      <Popup />
      <IEBumper />
    </GenericError>
  );
}

export default Shop;
