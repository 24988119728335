import React from 'react';
import { cx, css } from 'linaria';

const startPageSubtitle = css`
  text-align: center;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  padding: 0 1rem;
`;

const titleStyle = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  > * {
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
  }
`;

export const Title = ({ text, alignment, h1, style }) => {
  const Tag = h1?.value ? 'h1' : 'h2';
  return (
    <Tag
      id="title-test"
      className={Tag === 'h1' ? cx('title', style) : startPageSubtitle}
      dangerouslySetInnerHTML={{
        __html: text?.value
      }}
      style={{ textAlign: alignment?.value }}
    />
  );
};

export const StyledTitle = ({ text, alignment, h1 }) => {
  return <Title text={text} alignment={alignment} h1={h1} style={titleStyle} />;
};

