import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { Link } from 'react-router-dom';

const CTAWrapper = styled(Link)`
  font-size: 20px;
  font-weight: bold;
  border-radius: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  line-height: 51px;
  padding: 0 20px;
  display: inline-block;
  font-family: 'Figtree','Helvetica Neue', Helvetica, Arial, sans-serif;
  transition: all, 0.2s ease;

  &.primary {
    background: ${theme.colors.accent};
    :hover,
    :active {
      background: ${theme.colors.lightgreen};
    }
    a,
    div {
      color: ${theme.colors.white};
    }
    &.ghost {
      background: transparent;
      border: 1px solid ${theme.colors.accent};
      :hover,
      :active {
        background: ${theme.colors.accent};
      }
      a,
      div {
        color: ${theme.colors.accent};
      }
    }
  }

  &.black {
    background: ${theme.colors.primary};

    a,
    div {
      color: ${theme.colors.white};
    }
    &.ghost {
      background: transparent;
      border: 1px solid ${theme.colors.primary};
      a,
      div {
        color: ${theme.colors.primary};
      }
    }
  }

  &.tiny {
    padding: 0 10px;
    font-size: 9px;
    line-height: 106.3%;
    letter-spacing: 0.1em;
  }

  :hover,
  :active {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const CTAanchor = styled('a')`
  font-size: 20px;
  font-weight: bold;
  border-radius: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  line-height: 51px;
  padding: 0 20px;
  display: inline-block;
  font-family: 'Figtree','Helvetica Neue', Helvetica, Arial, sans-serif;
  transition: all, 0.2s ease;

  &.primary {
    background: ${theme.colors.accent};
    :hover,
    :active {
      background: ${theme.colors.lightgreen};
    }
    a,
    div {
      color: ${theme.colors.white};
    }
    &.ghost {
      background: transparent;
      border: 1px solid ${theme.colors.accent};
      :hover,
      :active {
        background: ${theme.colors.accent};
      }
      a,
      div {
        color: ${theme.colors.accent};
      }
    }
  }

  &.black {
    background: ${theme.colors.primary};

    a,
    div {
      color: ${theme.colors.white};
    }
    &.ghost {
      background: transparent;
      border: 1px solid ${theme.colors.primary};
      a,
      div {
        color: ${theme.colors.primary};
      }
    }
  }

  &.tiny {
    padding: 0 10px;
    font-size: 9px;
    line-height: 106.3%;
    letter-spacing: 0.1em;
  }

  :hover,
  :active {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const isRelativeUrl = url => {
  return (
    url &&
    !url.startsWith('http://') &&
    !url.startsWith('https://') &&
    !url.startsWith('mailto:') &&
    !url.startsWith('tel:')
  );
};

const CenterButtonContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  font-weight: ${theme.fontWeights.semibold};
`;

export const CTA = ({ testid, link, className, clickCallback, children }) => {
  if (isRelativeUrl(link)) {
    return (
      <CTAWrapper
        className={cx(className, 'cta-wrapper')}
        onClick={clickCallback}
        data-testid={testid || ''}
        to={link}
      >
        <CenterButtonContent>{children}</CenterButtonContent>
      </CTAWrapper>
    );
  }
  return (
    <CTAanchor
      className={cx(className, 'cta-wrapper')}
      onClick={clickCallback}
      data-testid={testid || ''}
      href={link}
    >
      <CenterButtonContent>{children}</CenterButtonContent>
    </CTAanchor>
  );
};
