import React from 'react'
import { css, cx } from "linaria";
import { theme } from "../Theme";


const bonusLevelsStyles = css`
  .bonus-levels {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bonus-level {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: inherit;
    margin-bottom: 3rem;
    position: relative;
  }

  .bonus-level-title {
    font-size: 2.3rem;
    font-weight: bold;
    margin-right: 1.5rem;
    color: #1A342D;
  }

  .circle-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .circle1,
  .circle2,
  .circle3,
  .circle4 {
    width: 22px;
    height: 22px;
    min-width: 22px; /* Keep circles round */
    background-color: ${theme.colors.black};
    border-radius: 50%;
    z-index: 1; /* Make circles appear above lines */
  }

  .line1,
  .line2,
  .line3 {
    flex: 1;
    height: 7px;
    margin: 0;
    background-color: ${theme.colors.black};
    position: relative;
    min-width: 50px; /* Ensure minimum length */
    margin: 0 -11px; /* Negative margin to overlap circles */
  }

  .bonus-level {
    &.level-1 {
        .circle1 {
            background-color: ${theme.colors.clubhooksgreen};
        }
        .circle2, .circle3, .circle4, .line1, .line2, .line3 {
            background-color: ${theme.colors.pink};
        }
    }
    &.level-2 {
        .circle1, .circle2, .line1, .line2 {
            background-color: ${theme.colors.clubhooksgreen};
        }
        .circle3, .circle4, .line2, .line3 {
            background-color: ${theme.colors.pink};
        }
    }
    &.level-3 {
        .circle1, .circle2, .circle3, .line1, .line2 {
            background-color: ${theme.colors.clubhooksgreen};
        }
        .circle4, .line3 {
            background-color: ${theme.colors.pink};
        }
    }
  }

  .bonus-info {
    position: absolute;
    top: 100%;
    margin-top: .5rem;
    text-align: left;
    width: 120px;
    font-size: 0.8rem;
    font-weight: bold;
    &.bonus-info-1 {
        left: 5px;
    }
    &.bonus-info-2 {
        left: 5px;
    }
    &.bonus-info-3 {
        left: 5px;
    }
    &.bonus-info-4 {
        text-align: right;
        right: 5px;
    }
    
  }
`;

const parseBonusLevelsText = (text) => {
    if (!text) return [];
  // Remove the [BonusLevels] header and split by level marker
  const levels = text
    .replace('<p>[BonusLevels]</p>\n', '')
    .split(/(<p>#\d<\/p>)/);

  const bonusData = [];

  // Start from index 1 to skip first empty string
  for (let i = 1; i < levels.length; i += 2) {
    const levelNumber = levels[i].match(/\d/)[0];
    const levelContent = levels[i + 1];

    // Extract the three p tags that follow
    const [bonus, range, points] = levelContent
      .trim()
      .split('\n')
      .slice(0, 3)
      .map(p => p.replace(/<\/?p>/g, ''));

    bonusData.push({
      level: parseInt(levelNumber),
      bonus,
      range,
      points
    });
  }

  return bonusData;
};

const BonusLevel = ({ level, bonusInfo }) => {
  return (
    <div className={cx("bonus-level", `level-${level}`)}>
      <div className="bonus-level-title">{level}</div>
      
      <div className="circle-container">
        <div className="circle1"></div>
        {level === 1 && bonusInfo && (
          <div className="bonus-info bonus-info-1">
            <div className="bonus">{bonusInfo.bonus}</div>
            <div className="range">{bonusInfo.range}</div>
            <div className="points">{bonusInfo.points}</div>
          </div>
        )}
      </div>
      
      <div className="line1"></div>
      
      <div className="circle-container">
        <div className="circle2"></div>
        {level === 2 && bonusInfo && (
          <div className="bonus-info bonus-info-2   ">
            <div className="bonus">{bonusInfo.bonus}</div>
            <div className="range">{bonusInfo.range}</div>
            <div className="points">{bonusInfo.points}</div>
          </div>
        )}
      </div>
      
      <div className="line2"></div>
      
      <div className="circle-container">
        <div className="circle3"></div>
        {level === 3 && bonusInfo && (
          <div className="bonus-info bonus-info-3">
            <div className="bonus">{bonusInfo.bonus}</div>
            <div className="range">{bonusInfo.range}</div>
            <div className="points">{bonusInfo.points}</div>
          </div>
        )}
      </div>
      
      <div className="line3"></div>
      
      <div className="circle-container">
        <div className="circle4"></div>
        {level === 4 && bonusInfo && (
          <div className="bonus-info bonus-info-4">
            <div className="bonus">{bonusInfo.bonus}</div>
            <div className="range">{bonusInfo.range}</div>
            <div className="points">{bonusInfo.points}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export const BonusLevels = ({ bonusText }) => {
  const bonusData = parseBonusLevelsText(bonusText);

  return (
    <div className={bonusLevelsStyles}>
      <div className="bonus-levels">
        {bonusData.map(data => (
          <BonusLevel 
            key={data.level} 
            level={data.level} 
            bonusInfo={data} 
          />
        ))}
      </div>
    </div>
  );
};