import { styled } from "linaria/react";
import t from '@jetshop/intl';
import get from 'lodash.get';
import React from 'react';
import { Query } from 'react-apollo';
import pagesQuery from './PagesQuery.gql';
import ContentPageLink from '@jetshop/ui/ContentPageLink';

const PageList = styled('div')`
  ul {
    display: flex;
    gap: .5rem;
    
    /* Create two columns */
    > div {
      flex: 1;
    }
    li {
      text-align: left;
      margin-bottom: -2px;
      a {
        line-height: 1.2; /* Tighter spacing between lines in the same link */
        display: inline-block; /* Helps maintain spacing */
      }
    }
    /* Option 1: Target the anchor tag directly */
    > div:nth-child(2) li:nth-child(1) a {
      margin-bottom: 10px;
    }
  }
`;

const FooterLinks = () => (
  <Query query={pagesQuery}>
    {({ data }) => {
      const pages = get(data, 'pages')?.filter(page => page.primaryRoute && page.id != 62);
      if (!pages) return null;

      // Split pages into two arrays - adjust numbers as needed
      const firstColumn = pages.slice(0, 8);
      const secondColumn = pages.slice(8);

      return (
        <PageList>
          <h2>{t('Customer service')}</h2>
          <ul>
            <div>
              {firstColumn.map(page => (
                <li key={page.id}>
                  {page.hasExternalUrl ? <a target={page.externalUrl.target} href={page.externalUrl.link}>{page.name}</a> : <ContentPageLink page={page}>{page.name}</ContentPageLink>}
                </li>
              ))}
            </div>
            <div>
              {secondColumn.map(page => (
                <li key={page.id}>
                  {page.hasExternalUrl ? <a target={page.externalUrl.target} href={page.externalUrl.link}>{page.name}</a> : <ContentPageLink page={page}>{page.name}</ContentPageLink>}
                </li>
              ))}
            </div>
          </ul>
        </PageList>
      );
    }}
  </Query>
);

export default FooterLinks;
