export const useVariantPricing = (selectedVariation, hasMemberPrice, loggedIn) => {
    let updatedVariant = { ...selectedVariation };
    if (!loggedIn && hasMemberPrice && selectedVariation !== undefined) {

      // Update the variant price
      updatedVariant = {
        ...updatedVariant,
        price: selectedVariation?.memberPrice,
        previousPrice: selectedVariation?.price,
      };
      return updatedVariant;
    }
    return selectedVariation;
  };