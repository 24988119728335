export function extractUspList(data) {
    // Check if the data structure is valid
    if (!data || !data.category || !data.category.data || !data.category.data.items) {
      return [];
    }
  
    // Extract the uspList
    const uspList = data.category.data.items.flatMap(item => {
      if (!item.children) return []; // Check if children exist
  
      return item.children.map(child => {
        // Check if properties exist and have at least two elements
        if (child.properties && child.properties.length > 1) {
          return child.properties[1].value.value; // Extract the string value
        }
        return null; // Return null if the structure is not as expected
      }).filter(value => value !== null); // Filter out any null values
    });
  
    return uspList;
  }