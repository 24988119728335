import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import { styled } from "linaria/react";
import { css } from "linaria";
import { theme } from "../Theme";
// import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
// import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import cartQuery from './CartQuery.gql';

import { ReactComponent as ShoppingBag } from '../../svg/fa/shoppingbag_new.svg';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;

  .cart-button-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    /* span {
      margin-left: 0.25em;
    } */
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  ${theme.above.sm} {
    width: unset;
  }
`;

const Badge = styled('span')`
  background: ${theme.colors.primary};
  color: #fff;
  font-size: 10px;
  height: 19px;
  width: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: -8px;
  right: -12px;
  font-weight: bold;
  ${theme.below.lg} {
    right: -10px;
  }
`;

function CartButton() {
  return (
    <Fragment>
      <ToastContainerWrapper>
        <StyledToastContainer
          toastClassName={css`
            padding: 0px;
            cursor: auto;
            font-family: inherit;
          `}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </ToastContainerWrapper>
      <CartProvider query={cartQuery}>
        {(result) => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

          return (
            <DrawerTrigger preventOverflow={true} id="cart-drawer">
              {(drawer) => (
                <Button
                  data-testid="header-cart"
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                  className="cart-button"
                >
                  <div className="cart-button-inner" data-testid="cart-button">
                    <ShoppingBag />
                    {itemsInCart > 0 &&
                      <Badge>{itemsInCart}</Badge>
                    }
                  </div>
                </Button>
              )}
            </DrawerTrigger>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButton;
