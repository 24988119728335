import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const LinkWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  p {
    font-family: ${theme.fonts.primary};
    font-size: 14px;
    font-weight: 500;
    line-height: 15.6px;
    margin: 10px 0;
    :hover {
      text-decoration: underline;
    }
  }
  a {
    text-decoration: none;
    color: black;
  }
`;

const LinkButton = styled('button')`
  font-size: 12px;
  line-height: 12px;
  border-radius: 50%;
  padding: 0; /* Adjust padding as needed */
  height: 100px;  
  width: 100px;  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  overflow: hidden; 
  :hover {
    transform: scale(0.90);
    transition: all 0.3s ease;
  }

  img {
    width: 100px;  
    height: 100px;  
    object-fit: cover;
  }

  ${theme.below.sm} {
    width: 85px;
    height: 85px;
  }
`;


export const CategoryLinkRowItem = ({ text, image, link }) => {
  const imageSizes = [1 / 4, 1 / 4, 1 / 4, 1 / 4];
  const { selectedChannel } = useContext(ChannelContext);
  const isMemberCategory = 
    text?.value?.includes('Member') || 
    text?.value?.includes('member') ||
    link?.value?.includes('member') ||
    link?.value?.includes('Member') ||
    image?.value?.value?.includes('member') ||
    image?.value?.value?.includes('Member');

  if (isMemberCategory && selectedChannel?.name === 'EU') {
    return null;
  }

  return (
    <LinkWrapper>
    <Link to={link.value}>
      <LinkButton aria-label='Link to category'>
        <Image 
          sizes={imageSizes}
          src={image?.value?.value} 
          alt={text?.value} 
          critical={true} 
          />
        {/* <img src={image?.value?.value} alt={text?.value} loading='eager' /> */}
      </LinkButton>
      <p>{text?.value}</p>
    </Link>
    </LinkWrapper>
  );
};
