import t from '@jetshop/intl';
import React, { useState, useEffect } from 'react';
import { Price } from '../Price';
import dayjs from 'dayjs';
import { styled } from 'linaria/react';

import { theme } from '../Theme';
import { productBrandStyle } from '../CategoryPage/RefinedProductCard';

export const PriceHistoryWrapper = styled('div')`
  line-height: 1rem;
  margin-bottom: 3px;
  button,
  span {
    background: none;
    font-size: 12px;
  }
  span {
    line-height: 2;
    opacity: 0.5;
  }

  button {
    text-decoration: underline;
    font-weight: 700;
  }
  position: relative;

  /* margin-top: -2.25rem;
  margin-bottom: 2.5rem; */
  /* ${theme.below.md} {
    margin-top: -1rem;
    margin-bottom: 0.5rem;
  } */
  .price-flyout {
    background: #e9e9e9;
    position: absolute;
    top: calc(100% + 10px);
    max-width: 100%;
    left: 0;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 1.5rem;
    z-index: 9;
    h3 {
      display: block;
      font-size: 14px;
    }
    &::before {
      position: absolute;
      top: -10px;
      left: 30px;
      transform: translatex(-50%);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #e9e9e9 transparent;
    }
  }
  ul {
    margin: 0 0;
    font-size: 14px;
    li {
      padding: 4px 0px;
      width: 100%;

      div {
        display: contents;
      }
    }
  }
`;

export const PriceHistory = ({ product, selectedVariation }) => {
  const history = (
    selectedVariation || product
  )?.history?.previousPrice?.filter(p => p.price.incVat !== 0);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    function clickOutside(event) {
      if (!event.target.closest('#priceHistory')) {
        setOpen(false);
      }
    }

    window.addEventListener('click', clickOutside);
    return () => window.removeEventListener('click', clickOutside);
  }, []);

  const currentDate = new Date();
  const thirtyDaysAgo = currentDate.setDate(currentDate.getDate() - 30);
  const updatedHistory =
    history && [...history].filter(h => new Date(h.timestamp) > thirtyDaysAgo);

  const nothing =
    (history?.length === 0 && !product.hasVariants) ||
    (selectedVariation && (!history || updatedHistory.length === 0));

  return (
    <PriceHistoryWrapper id="priceHistory">
      <button className={productBrandStyle} onClick={() => setOpen(!open)}>
        {t('View price history')}
      </button>
      {open && (
        <div className="price-flyout">
          {nothing && <h3>{t('No price changes within the last 30 days')}</h3>}
          {(!history || history?.length === 0) &&
            product.hasVariants &&
            !selectedVariation && (
              <h3>{t('Please select a variant to show price history.')}</h3>
            )}

          {updatedHistory?.length > 0 && (
            <>
              <h3>{t('Price history for the last 30 days.')}</h3>
              <ul>
                {updatedHistory.map(h => (
                  <li>
                    {dayjs(h.timestamp).format('YYYY-MM-DD')} -{' '}
                    <Price price={h.price} />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </PriceHistoryWrapper>
  );
};
