import Image from '@jetshop/ui/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { cx } from 'linaria';
import React from 'react';
import { styled } from 'linaria/react';
import { Price } from '../Price';
import { theme } from '../Theme';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { badgePriority } from '../../utils/badgePriority';

const Wrapper = styled('li')`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  .product-card-detail {
    background: white;
    padding: 0.75em;
    line-height: 1.35;
    width: 100%;
    text-align: center;
    h3 {
      font-weight: 500;
      font-size: 16px;
      white-space: wrap;
      overflow: hidden;
      color: ${theme.colors.primary};

      ${theme.below.sm} {
        font-size: 14px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 11px;
      color: ${theme.colors.mediumgrey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
    }

    .price-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 10px;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      ${theme.below.sm} {
        font-size: 14px;
        margin-top: 0;
      }
      > div {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: flex-end;
        div {
          margin-right: 10px;
          color: ${theme.colors.primary};
        }
        .new-price {
          color: #b13827;
          margin-right: 8px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-family: 'Figtree';
          font-size: 1.5rem;
        }
        .price {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-family: 'Figtree';
          font-size: 1.5rem;
          margin-right: 0;
        }
        .old-price {
          color: ${theme.colors.grey};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-bottom: 5px;
          margin-right: 5px;
          font-weight: normal;
        }
        .member-price {
          color: ${theme.colors.pink};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-weight: bold;
          font-family: 'Figtree';
          font-size: 1.5rem;
        }
      }
    }
  }

  .badges-wrapper {
    & > div > div {
      top: 10px;
      div {
        margin-bottom: 5px;
      }
    }
    > div > div > div {
      font-weight: bold;
      font-size: 0.55rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1.1;
      text-align: center;
      width: 65px;
      height: 25px;
      color: white;
      background: #235837;
      ${theme.below.md} {
        height: 20px;
        font-size: 0.5rem;
        padding-top: 1px;
      }

      &.sale-tagg {
        background: #872C2B;
      }

      &.member-tagg {
        background: #b08832;
      }

      &.best-price-tagg {
        background: #ef7622;
      }

      &.new-tagg {
        background: #000;
      }
      
      &.member-deal-tagg {
        background: ${theme.colors.pink};
      }
    }
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.03);
    pointer-events: none;
    z-index: 1;
  }
`;

const AddFavoriteButtonWrapper = styled('div')`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;

  ${theme.below.sm} {
    top: 10px;
    right: 10px;
    .favorite-icon svg {
      width: 20px;
    }
  }
`;

export function ToastProductCard({
  product,
  className,
  imageAspect = '1:1',
  selectedVariation,
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  ...linkProps
}) {
  const { loggedIn } = useAuth();
  let uniqueBadges = badgePriority(product.badges, 'name');
  const hasImages = product.images && product.images.length > 0;
  const hasMemberPrice = product?.hasMemberPrice;
  return (
    <Wrapper data-testid="product" className={cx('product-card', className)}>
      <ProductLink product={product} {...linkProps}>
        <ImageWrapper>
          {hasImages ? (
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
              modifiedDate={product.images[0].modifiedDate}
            >
              <div className="badges-wrapper">
                <Badges
                  badges={
                    loggedIn
                      ? uniqueBadges
                      : uniqueBadges.filter((badge) => badge.name !== 'Medlem')
                  }
                />
              </div>
            </Image>
          ) : (
            <Image aspect={imageAspect} />
          )}
        </ImageWrapper>

        <section className="product-card-detail">
          <h3>{product.name}</h3>
          <div className="price-wrapper">
            <Price
              hasMemberPrice={hasMemberPrice}
              price={(selectedVariation || product).price}
              previousPrice={(selectedVariation || product).previousPrice}
            />
          </div>
        </section>
      </ProductLink>
    </Wrapper>
  );
}
